import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import SbEditable from 'storyblok-react';

import FullWidthImage from '../FullWidthImage';
import FiftyFiftyTextBlock from '../FiftyFiftyTextBlock';

//

const FullWidthImageWithCTA = ({ content }) => (
  <SbEditable content={content}>
    <FullWidthWrap
      imageOffset={content.ImageBlock}
      data-sal="fade"
      data-duration="750"
    >
      {content.ImageBlock && (
        <FullWidthImage
          content={content.ImageBlock[0]}
          customTheme={content.BgColor}
          keepCaption={false}
        />
      )}
      <FiftyFiftyTextBlock content={content} />
    </FullWidthWrap>
  </SbEditable>
);

export default FullWidthImageWithCTA;

const FullWidthWrap = styled.section`
  ${(props) =>
    props.imageOffset &&
    css`
      section + section {
        position: relative;
        top: -0.1rem;
        padding-top: 0.1rem;
      }
    `}
`;

FullWidthImageWithCTA.propTypes = {
  content: PropTypes.shape({
    ImageBlock: PropTypes.array,
  }),
};

FullWidthImageWithCTA.defaultProps = {
  content: {
    ImageBlock: null,
  },
};
