import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';

//

const QuoteTextBlock = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  return (
    <SbEditable content={content}>
      <QuoteWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <QuoteContainer>
          <blockquote>
            <p>
              <span>&ldquo;</span>
              {content.Blockquote}
              <span>&rdquo;</span>
            </p>
            {content.CitationName && (
              <footer>
                <cite>
                  {content.CitationName}
                  <span>{content.CitationRole}</span>
                </cite>
              </footer>
            )}
          </blockquote>
        </QuoteContainer>
      </QuoteWrap>
    </SbEditable>
  );
};

export default QuoteTextBlock;

const QuoteContainer = styled(Container)`
  width: 100%;
  padding: 0;
`;

const QuoteWrap = styled.section`
  padding: 5.6rem 1.6rem;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    padding: 4.8rem;
  }

  blockquote {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    padding-bottom: 7.4rem;
    border-bottom: 1px solid var(--${(props) => props.themeDivider});
    font-family: var(--font-serif);
    text-align: center;

    @media (min-width: 640px) {
      grid-template-columns: 3fr 1fr;
      padding-bottom: 11.2rem;
      text-align: left;
    }

    @media (min-width: 768px) {
      grid-template-columns: 4fr 1fr;
    }
  }

  p {
    position: relative;
    font-size: 3.4rem;
    line-height: 4rem;

    span {
      &:first-of-type {
        margin-right: 0.4rem;

        @media (min-width: 640px) {
          position: absolute;
          left: -1.8rem;
          margin-right: unset;
        }
      }

      &:last-of-type {
        margin-left: 0.4rem;
      }
    }

    @media (min-width: 1024px) {
      font-size: 4.2rem;
      line-height: 4.8rem;
    }
  }

  cite {
    display: flex;
    flex-direction: column;
    margin-top: 6.4rem;
    font-family: var(--font-serif);
    font-size: 1.8rem;
    font-style: normal;
    line-height: 2.4rem;
    text-align: center;

    @media (min-width: 640px) {
      margin-top: unset;
      text-align: right;
    }

    span {
      margin-top: 0.2rem;
      color: var(--${(props) => props.themeHighlight});
      font-family: var(--font-sans);
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
`;

QuoteTextBlock.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Blockquote: PropTypes.string,
    CitationName: PropTypes.string,
    CitationRole: PropTypes.string,
  }),
};

QuoteTextBlock.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
    Blockquote: '...',
    CitationName: null,
    CitationRole: null,
  },
};
