import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ColorPicker from '../../helpers/ColorPicker';

//

const Spacer = ({ content }) => {
  const theme = ColorPicker(content.BgColor, false);

  return (
    <SpacerWrap
      space={Number(content.Space)}
      themeBackground={theme.background}
    />
  );
};

export default Spacer;

const SpacerWrap = styled.section`
  width: 100%;
  height: ${(props) => props.space / 10}rem;
  background-color: var(--${(props) => props.themeBackground});
`;

Spacer.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    Space: PropTypes.string,
  }),
};

Spacer.defaultProps = {
  content: {
    BgColor: 'white',
    Space: 24,
  },
};
