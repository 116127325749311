import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HubspotForm from 'react-hubspot-form';
import SbEditable from 'storyblok-react';

import Container from '../Container';

//

const Hubspot = ({ content }) => (
  <SbEditable content={content}>
    <HubspotWrap >
      <HubspotContainer>
        <HubspotForm
          portalId={content.PortalID}
          formId={content.FormID}
          loading={<Loading>Loading form...</Loading>}
        />
      </HubspotContainer>
    </HubspotWrap>
  </SbEditable>
);

export default Hubspot;

const HubspotContainer = styled(Container)`
  width: 100%;
  padding: 0;
`;

const HubspotWrap = styled.section`
  padding: 3rem;

  @media (min-width: 640px) {
    padding: 4rem;
  }
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey--0);
  font-size: 1.6rem;
  text-align: center;
`;

Hubspot.propTypes = {
  content: PropTypes.shape({
    PortalID: PropTypes.string,
    FormID: PropTypes.string,
  }),
};

Hubspot.defaultProps = {
  content: {
    PortalID: 5325021,
    FormID: null,
  },
};
