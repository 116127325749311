import React from 'react';
import PropTypes from 'prop-types';
import StoryblokComponents from '../../storyblok/StoryblokComponents';

//

// *
// ** Note:
// ** This page is only used by the Storyblok Editor
// ** The actual content is done in templates/Page.js

//

const Page = ({ content }) => {
  const blocks = content.map((block) =>
    React.createElement(StoryblokComponents(block.component), {
      key: block._uid,
      content: block,
    })
  );

  return blocks;
};

export default Page;

Page.propTypes = {
  content: PropTypes.array.isRequired,
};
