import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PolygonHourglass from '../../assets/shapes/polygon-hourglass.inline.svg';
import BeltPinch from '../../assets/shapes/belt-pinch.inline.svg';

//

const ShapeInject = ({ content }) => {
  const ShapeRender = () => {
    switch (content.Shape) {
      case 'PolygonHourglass':
        return <PolygonHourglass />;
      default:
        return <BeltPinch />;
    }
  };

  const SolidColor = content.SolidColor.includes('--3')
    ? content.SolidColor.replace('--3', '--2')
    : content.SolidColor.replace('--0', '--1');
  const OutlineColor = content.OutlineColor.includes('--3')
    ? content.OutlineColor.replace('--3', '--2')
    : content.OutlineColor.replace('--0', '--1');

  return (
    <ShapeWrap
      data-sal="fade"
      data-duration="750"
      position={content.Position}
      SolidColor={SolidColor}
      OutlineColor={OutlineColor}
    >
      <ShapeRender />
    </ShapeWrap>
  );
};

export default ShapeInject;

const ShapeWrap = styled.section`
  position: absolute;
  z-index: 125;

  svg {
    width: 50%;
    height: 50%;
    transform: ${(props) =>
      props.position === 'left'
        ? 'translate(-50%, -50%)'
        : 'translate(calc(100vw - 50%), -50%)'};

    .solid {
      fill: var(--${(props) => props.SolidColor});
    }

    .outline {
      stroke: var(--${(props) => props.OutlineColor});
    }
  }
`;
