import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

//

const CarouselControls = ({
  activeSlide,
  totalSlides,
  prevRef,
  nextRef,
  className,
  themeText,
  themeHighlight,
  setActiveSlide,
}) => {
  const isLastSlide = activeSlide === totalSlides;

  useEffect(() => {
    const rightButton = nextRef.current;
    if (rightButton && rightButton.classList.contains('swiper-button-disabled')) {
      setActiveSlide(totalSlides);
      activeSlide = totalSlides;
    }

    console.log("useEffect" , activeSlide);
    console.log("useEffect" , totalSlides);
  }, [nextRef, setActiveSlide, totalSlides]);

  const handleNextClick = () => {
    if (isLastSlide) {
      setActiveSlide(totalSlides);
      activeSlide = totalSlides;
    }

    console.log("handleNextClick ",activeSlide);
    console.log("handleNextClick ",totalSlides);
  };

  return (
    <Controls
      className={className}
      themeText={themeText}
      themeHighlight={themeHighlight}
    >
      <button
        type="button"
        className="Controls--left"
        ref={prevRef}
        aria-label="Previous Slide"
      >
        <BsArrowLeftShort />
      </button>
      {activeSlide} of {totalSlides}
      <button
        type="button"
        className={`Controls--right ${isLastSlide ? 'Controls--disabled' : ''}`}
        ref={nextRef}
        aria-label="Next Slide"
        disabled={isLastSlide}
        onClick={handleNextClick}
      >
        <BsArrowRightShort />
      </button>
    </Controls>
  );
};


export default CarouselControls;

const Controls = styled.div`
  display: flex;
  z-index: 20;
  justify-content: center;
  font-size: 1.4rem;
  line-height: 2.4rem;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }

  button {
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
    color: var(--${(props) => props.themeHighlight});
    cursor: pointer;

    &.swiper-button-disabled {
      opacity: 0.25;
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
  }

  .Controls--left {
    margin-right: 1.6rem;
  }

  .Controls--right {
    margin-left: 1.6rem;
  }

  .Controls--disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
  }
`;

CarouselControls.propTypes = {
  activeSlide: PropTypes.number,
  totalSlides: PropTypes.number,
  prevRef: PropTypes.object,
  nextRef: PropTypes.object,
  className: PropTypes.string,
  themeText: PropTypes.string,
  themeHighlight: PropTypes.string,
};

CarouselControls.defaultProps = {
  activeSlide: 1,
  totalSlides: null,
  prevRef: null,
  nextRef: null,
  className: null,
  themeText: 'white',
  themeHighlight: 'white',
};
