import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';
import Icon from '../Icon';
import ImageMask from '../ImageMask';

//

const IconListWithImage = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  return (
    <SbEditable content={content}>
      <IconListWithImageWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <IconListWithImageContainer>
          <div className="ILWI__list">
            {content.Subtitle && <h3>{content.Subtitle}</h3>}
            {content.Headline && <h2>{content.Headline}</h2>}

            {content.List && (
              <ul className="ILWI__list--items">
                {content.List.map((item) => (
                  <li>
                    <Icon shape={item.Icon} /> {item.Copy}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="ILWI__image">
            <ImageMask
              shape={content.Image[0].ImageShape}
              src={content.Image[0].ImageSource}
              contain={content.Image[0].ImageContain}
            />
          </div>
        </IconListWithImageContainer>
      </IconListWithImageWrap>
    </SbEditable>
  );
};

export default IconListWithImage;

const IconListWithImageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const IconListWithImageWrap = styled.section`
  padding: 3rem;
  overflow-x: hidden;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    padding: 4.2rem;
  }

  .ILWI__list {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }

    h3 {
      margin-bottom: 0.4rem;
      color: var(--${(props) => props.themeHighlight});
      font-size: 1.4rem;
      line-height: 2rem;
    }

    h2 {
      font-family: var(--font-serif);
      font-size: 3.2rem;
      line-height: 3.8rem;

      @media (min-width: 1024px) {
        font-size: 4.2rem;
        line-height: 4.8rem;
      }
    }
  }

  .ILWI__list--items {
    display: grid;
    grid-gap: 2.4rem;
    grid-template-columns: 1fr;
    margin: 0;
    margin-top: 4rem;
    padding: 0;
    padding-top: 4rem;
    border-top: 1px solid var(--${(props) => props.themeDivider});
    list-style: none;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      display: flex;
      align-items: center;
      font-family: var(--font-serif);
      font-size: 2rem;
      line-height: 2.4rem;

      @media (min-width: 1024px) {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }

      div {
        display: flex;
      }
    }

    .icon {
      margin-right: 1.6rem;
      fill: var(--${(props) => props.themeHighlight});
    }
  }

  .ILWI__image {
    width: 100%;
    margin-top: 3rem;

    @media (min-width: 768px) {
      width: calc(50% - 3.2rem);
      margin-top: unset;
    }

    @media (min-width: 1024px) {
      width: calc(50% - 5.6rem);
    }
  }
`;

IconListWithImage.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Subtitle: PropTypes.string,
    Headline: PropTypes.string,
    List: PropTypes.array,
    Image: PropTypes.array,
  }),
};

IconListWithImage.defaultProps = {
  content: {
    BgColor: 'white',
    AltColors: false,
    Subtitle: null,
    Headline: null,
    List: null,
    Image: null,
  },
};
