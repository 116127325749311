import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import Container from '../Container';
import ColorPicker from '../../helpers/ColorPicker';
import GenericCarousel from '../GenericCarousel';
import Awesome from './Awesome';
import CarouselControls from '../CarouselControls';
import Carousel from './Carousel';

//

const Carousels = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [totalSlides, setTotalSlides] = useState(null);
  const [activeSlide, setActiveSlide] = useState(null);

  return (
    <SbEditable content={content}>
      <CarouselsWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <CarouselsContainer>
          <div className="RPW__carousel">
            <Awesome
              play={false}
              cancelOnInteraction={false} // should stop playing on user interaction
              interval={6000}
              theme={theme}
            >
              {content.Pills?.map((pill) => (
                <Carousel key={pill._uid} content={pill} theme={theme} />
              ))}
            </Awesome>
          </div>
        </CarouselsContainer>
      </CarouselsWrap>
    </SbEditable>
  );
};

export default Carousels;

const CarouselsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  overflow: visible;

  @media (min-width: 640px) {
    flex-direction: row;
  }
`;

const CarouselsWrap = styled.section`
  padding: 4rem;
  overflow: hidden;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    padding: 4.8rem;
  }

  .RPW__intro {
    display: flex;
    position: relative;
    z-index: 5;
    flex-direction: column;
    width: calc(100% - 2.4rem);
    padding-right: 2.4rem;
    background-color: var(--${(props) => props.themeBackground});

    @media (min-width: 640px) {
      width: calc(40% - 2.4rem);
    }

    @media (min-width: 1024px) {
      width: calc(35% - 2.4rem);
    }

    &::before {
      @media (min-width: 640px) {
        content: '';
        display: block;
        position: absolute;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: var(--${(props) => props.themeBackground});
      }
    }

    .RPW__intro--copy {
      h3 {
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        line-height: 2rem;
      }

      h2 {
        margin-bottom: 3.2rem;
        font-family: var(--font-serif);
        font-size: 3.2rem;
        line-height: 3.8rem;

        &.RPW__header--alt {
          max-width: 40rem;
          margin-top: 1.6rem;
          padding-left: 2.4rem;
          border-left: 1px solid var(--${(props) => props.themeDivider});
        }

        @media (min-width: 1024px) {
          font-size: 4.2rem;
          line-height: 4.8rem;
        }
      }
    }

    .RPW__intro--controls {
      display: none;

      @media (min-width: 640px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
      }
    }
  }

  .RPW__carousel {
    position: relative;
    z-index: 0;
    width: 100%;
    }
  }
`;


const Slides = styled(GenericCarousel)`
  align-items: stretch;
  padding-bottom: 4.8rem;
  overflow: visible;

  .swiper-scrollbar {
    background-color: transparent;

    .swiper-scrollbar-drag {
      background-color: var(--${(props) => props.themeText});
    }

    &::after {
      background-color: var(--${(props) => props.themeText});
    }
  }

  .swiper-slide {
    display: flex;
    align-items: stretch;
    /*width: auto;
     max-width: 32rem; */
     width:92vw;
    height: unset;
    padding: 0 1.5rem;

    &:first-of-type {
      padding-left: 1%;
    }

    &:last-of-type {
      padding-right: 1%;
    }
  }

  .swiper-slide-prev {
    display: flex;
    align-items: stretch;
    /*width: auto;
     max-width: 32rem; */
     width:92vw;
    height: unset;
    padding: 0 1.5rem;

    @media (min-width: 768px)
    {
      width:90vw; 
      padding-right: 3rem;
    }

    @media (min-width: 640px) and (max-width: 768px)
    {
      width:85vw; 
      padding-right: 3rem;
    }

    @media (min-width: 468px) and (max-width: 640px)
    {
      width:87vw; 
      padding-right: 3rem;
    }

    @media (min-width: 320px) and (max-width: 468px)
    {
      width:81vw; 
      padding-right: 3rem;
    }
  }

  .swiper-slide-active {
    display: flex;
    align-items: stretch;
    /*width: auto;
     max-width: 32rem; */
     width:92vw;
    height: unset;
    padding: 0 1.5rem;

    @media (min-width: 768px)
    {
      width:90vw; 
      padding-right: 3rem;
      padding-left: 3rem;
    }

    @media (min-width: 640px) and (max-width: 768px)
    {
      width: 90vw;
      padding-right: 3rem;
      padding-left: 3rem;
    }

    @media (min-width: 468px) and (max-width: 640px)
    {
      width:87vw; 
      padding-right: 3rem;
      padding-left: 3rem;
    }

    @media (min-width: 320px) and (max-width: 468px)
    {
      width:81vw; 
      padding-right: 3rem;
      padding-left: 3rem;
    }
`;

Carousels.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Subtitle: PropTypes.string,
    Headline: PropTypes.string,
    component: PropTypes.string,
    Pills: PropTypes.array,
  }),
};

Carousels.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
    Subtitle: null,
    Headline: null,
    component: null,
    Pills: null,
  },
};
