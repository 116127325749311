import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import Richtext from '../../helpers/Richtext';
import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';

//

const IntroTextBlock = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  return (
    <SbEditable content={content}>
      <ITB
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <GridContainer>
          <div className="ITB__column">
            <div className="ITB__heading">
              <h2>{content.HeadlineText}</h2>
              <p>{content.SubHeading}</p>
            </div>

            <div className="ITB__content ITB__content--grid">
              <div className="ITB__content--column">
                <p className="ITB__content--intro">{content.MainTopicIntro}</p>
                <div
                  className="ITB__content--copy"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: Richtext(content.MainTopic),
                  }}
                />
              </div>

              <div className="ITB__content--column">
                <div className="ITB__content--section">
                  <h3>{content.SecondaryTopicHeading}</h3>
                  <div
                    className="ITB__content--copy"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: Richtext(content.SecondaryTopic),
                    }}
                  />
                </div>

                <div className="ITB__content--section">
                  <h3>{content.TertiaryTopicHeading}</h3>
                  <div
                    className="ITB__content--copy"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: Richtext(content.TertiaryTopic),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ITB__column">
            <div className="ITB__heading">
              <h3>{content.ListHeadline}</h3>
            </div>

            <div className="ITB__content">
              <ol className="ITB__list">
                {content.ListItems.map((item, index) => (
                  <li key={item._uid}>
                    <span className="ITB__list--number">0{index + 1}</span>
                    <p>{item.ListItemCopy}</p>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </GridContainer>
      </ITB>
    </SbEditable>
  );
};

export default IntroTextBlock;

const GridContainer = styled(Container)`
  display: grid;
  grid-gap: 7.6rem;
  grid-template-columns: 1fr;
  width: 100%;
  padding: 0;

  @media (min-width: 768px) {
    grid-gap: 2rem;
    grid-template-columns: 1.5fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 3fr 1fr;
  }
`;

const ITB = styled.section`
  padding: 4rem 1.6rem;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});
  font-family: var(--font-sans);

  @media (min-width: 768px) {
    padding: 4.8rem;
  }

  h2,
  h3,
  .ITB__content--intro,
  .ITB__list--number {
    color: var(--${(props) => props.themeHighlight});
    font-family: var(--font-serif);
  }

  .ITB__heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    max-width: 64rem;

    @media (min-width: 1024px) {
      min-height: 7.6rem;
    }

    h2 {
      font-size: 4.2rem;
      line-height: 4.8rem;
    }

    h3 {
      font-size: 2.6rem;
      line-height: 2.8rem;
    }

    p {
      margin-top: 0.8rem;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  .ITB__content {
    margin-top: 4rem;
    padding-top: 4rem;
    border-top: 1px solid var(--${(props) => props.themeDivider});

    @media (min-width: 768px) {
      margin-top: 4.8rem;
      padding-top: 4.8rem;
    }

    p {
      margin-top: 2.4rem;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    a {
      transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) opacity;
      opacity: 1;
      color: var(--${(props) => props.themeHighlight});
      text-decoration: underline;
      text-decoration-color: var(--${(props) => props.themeText});

      &:hover {
        opacity: 0.8;
        text-decoration-color: var(--${(props) => props.themeHighlight});
      }
    }

    &--grid {
      display: grid;
      grid-gap: 5.6rem;
      grid-template-columns: 1fr;

      @media (min-width: 1024px) {
        grid-gap: 9.6rem;
        grid-template-columns: 1fr 1.5fr;
      }

      @media (min-width: 1280px) {
        grid-template-columns: 1fr 1.75fr;
      }
    }

    .ITB__content--intro {
      max-width: 48rem;
      margin-top: 0;
      font-size: 2.6rem;
      line-height: 3.2rem;
    }

    .ITB__content--section {
      max-width: 48rem;

      &:nth-of-type(1) {
        padding-bottom: 4rem;
        border-bottom: 1px solid var(--${(props) => props.themeDivider});

        @media (min-width: 768px) {
          padding-bottom: 4.8rem;
        }
      }

      &:nth-of-type(2) {
        margin-top: 4rem;

        @media (min-width: 768px) {
          padding-bottom: 4.8rem;
        }
      }
    }
  }

  .ITB__list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.2rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    p {
      margin-top: 0;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    .ITB__list--number {
      margin-bottom: 0.2rem;
      font-size: 1.8rem;
      letter-spacing: 0.4rem;
      line-height: 2.4rem;
      font-variant-numeric: oldstyle-nums;
    }
  }
`;

IntroTextBlock.propTypes = {
  content: PropTypes.object.isRequired,
};
