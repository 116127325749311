import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import FiftyFiftyTextBlock from '../FiftyFiftyTextBlock';
import GenericCarousel from '../GenericCarousel';
import SquareSlide from './SquareSlide';
import Shape from '../Shape';

//

const SquareCarousel = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  return (
    <SbEditable content={content}>
      <SquareWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <FiftyFiftyTextBlock
          customTheme={theme}
          content={content}
          transparent
        />

        <Slider>
          {content.Slider.map((slide) => (
            <SquareSlide
              key={slide._uid}
              headline={slide.Headline}
              copy={slide.Copy}
              icon={slide.Icon}
              image={slide.Image}
              theme={theme}
              link={slide.Link}
            />
          ))}
        </Slider>

        <BgShape shape={content.BgShape} color={theme.divider} />
      </SquareWrap>
    </SbEditable>
  );
};

export default SquareCarousel;

const Slider = styled(GenericCarousel)`
  z-index: 10;
`;

const SquareWrap = styled.section`
  position: relative;
  padding: 10rem 4.8rem 4.8rem;
  overflow: hidden;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  section {
    position: relative;
    z-index: 10;
    padding: 0 0 5.6rem 0;

    @media (min-width: 768px) {
      padding: 0 0 12.6rem 0;
    }

    > div {
      width: 100%;
      padding: 0;

      @media (min-width: 768px) {
        width: 85%;
      }
    }
  }

  .swiper-container {
    padding-bottom: 10rem;
    overflow: visible;
  }

  .swiper-scrollbar {
    background-color: transparent;

    .swiper-scrollbar-drag {
      background-color: var(--${(props) => props.themeText});
    }

    &::after {
      background-color: var(--${(props) => props.themeText});
    }
  }

  .swiper-slide {
    width: 75%;
    min-width: 40rem;
    max-width: 56rem;
    margin-right: 2.4rem;

    &:last-of-type {
      margin-right: 0;
    }

    @media (min-width: 640px) {
      width: 75%;
      max-width: 56rem;
    }
  }
`;

const BgShape = styled(Shape)`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  width: 100%;
  min-width: 102.4rem;
  height: 100%;
  transform: translate(-50%, -50%);

  svg {
    width: calc(100% - 9.4rem);
    height: calc(100% - 9.4rem);
    margin: 4.8rem;
  }
`;

SquareCarousel.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Slider: PropTypes.array,
  }),
};

SquareCarousel.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
    Slider: null,
  },
};
