import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//

const QuoteMark = ({ fill, flip }) => (
  <QuoteMarkWrap fillColor={fill} flip={flip}>
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.99347 3.73564C4.61159 1.8509 7.20059 0.829996 10.9223 0.751465V5.85597C9.46596 6.01304 8.41418 6.48422 7.84784 7.26953C7.2815 8.05484 6.95787 9.2328 6.95787 10.882V11.5887H10.9223V23.0542H0.566284V12.2955C0.566284 8.44749 1.37535 5.62038 2.99347 3.73564ZM17.0711 3.73564C18.6893 1.8509 21.2783 0.829996 24.9999 0.751465V5.85597C23.5436 6.01304 22.4919 6.48422 21.9255 7.26953C21.3592 8.05484 21.0355 9.2328 21.0355 10.882V11.5887H24.9999V23.0542H14.644V12.2955C14.644 8.44749 15.453 5.62038 17.0711 3.73564Z" />
    </svg>
  </QuoteMarkWrap>
);

export default QuoteMark;

const QuoteMarkWrap = styled.div`
  display: flex;
  justify-content: center;

  svg {
    transform: scale(${(props) => (props.flip ? '-1' : '1')});
    fill: var(--${(props) => props.fillColor});
  }
`;

QuoteMark.propTypes = {
  fill: PropTypes.string,
  flip: PropTypes.bool,
};

QuoteMark.defaultProps = {
  fill: 'white',
  flip: false,
};
