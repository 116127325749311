import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BevelledCorners from '../../assets/shapes/bevelled-corners.inline.svg';
import Hourglass from '../../assets/shapes/hourglass.inline.svg';
import Pinched from '../../assets/shapes/pinched.inline.svg';
import Round from '../../assets/shapes/round.inline.svg';
import SlightlyRound from '../../assets/shapes/slightly-round.inline.svg';
import SquaredCorners from '../../assets/shapes/squared-corners.inline.svg';

//

const Shape = ({ shape, color, className }) => {
  const ShapeRender = () => {
    switch (shape) {
      case 'BevelledCorners':
        return <BevelledCorners />;
      case 'Pinched':
        return <Pinched />;
      case 'Round':
        return <Round />;
      case 'SlightlyRound':
        return <SlightlyRound />;
      case 'SquaredCorners':
        return <SquaredCorners />;
      default:
        return <Hourglass />;
    }
  };

  return (
    <ShapeWrap className={className} svgColor={color}>
      <ShapeRender />
    </ShapeWrap>
  );
};

export default Shape;

const ShapeWrap = styled.div`
  svg {
    width: 100%;
    height: 100%;
    fill: var(--${(props) => props.svgColor});
    color: var(--${(props) => props.svgColor});
  }
`;

Shape.propTypes = {
  shape: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

Shape.defaultProps = {
  shape: 'hourglass',
  color: 'black',
  className: null,
};
