import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';
import RolloverPill from '../RolloverPills/RolloverPill';

//

const RolloverPillsThreeUp = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  return (
    <SbEditable content={content}>
      <ThreeUpWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <ThreeUpContainer>
          {content.Pills.map((pill) => (
            <RolloverPill key={pill._uid} content={pill} theme={theme} />
          ))}
        </ThreeUpContainer>
      </ThreeUpWrap>
    </SbEditable>
  );
};

export default RolloverPillsThreeUp;

const ThreeUpContainer = styled(Container)`
  display: grid;
  grid-gap: 3.2rem;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  padding: 0;
`;

const ThreeUpWrap = styled.section`
  padding: 3rem 0;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 1024px) {
    padding: 4.8rem;
  }
`;

RolloverPillsThreeUp.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
  }),
};

RolloverPillsThreeUp.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
  },
};
