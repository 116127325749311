import React from 'react';

//

const ComponentMissing = () => (
  <pre>
    Component not found, please contact{' '}
    <a href="mailto:hey@chrish.design">hey@chrish.design</a> if this error
    persists
  </pre>
);

export default ComponentMissing;
