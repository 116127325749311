import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import Container from '../Container';
import ColorPicker from '../../helpers/ColorPicker';
import GenericCarousel from '../GenericCarousel';
import Awesome from './Awesome';
import CarouselControls from '../CarouselControls';
import Banner from './Banner';

//

const Banners = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [totalSlides, setTotalSlides] = useState(null);
  const [activeSlide, setActiveSlide] = useState(null);

//console.log(content.Pills);
  
  return (
    <SbEditable content={content}>
      <BannersWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <BannersContainer>
          <div className="RPW__carousel">
            <Awesome
              organicArrows={true}
              play={true}
              cancelOnInteraction={false} // should stop playing on user interaction
              interval={5000}
              bullets={false}
            >
              {content.Pills?.map((pill) => (
                <Banner key={pill._uid} content={pill} theme={theme} />
              ))}
            </Awesome>
          </div>
        </BannersContainer>
      </BannersWrap>
    </SbEditable>
  );
};

export default Banners;

const BannersContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  overflow: visible;
  max-width:1080px;

  @media (min-width: 640px) {
    flex-direction: row;
  }
`;

const BannersWrap = styled.section`
  /*padding: 4rem;*/
  overflow: hidden;
  /*background-color: var(--${(props) => props.themeBackground});*/
  background-color: transparent;
  color: var(--${(props) => props.themeText});
  /*height: 100vh!important;*/

  @media (min-width: 640px) {
    /*padding: 4.8rem;*/
  }
/*
  @media (min-width: 1920px) and (min-height: 1080px) {
    height: 65vh!important;
  }

  @media (min-width: 1080px) and (min-height: 1920px) {
    height: 35vh!important;
  }

  @media (min-width: 1280px) and (min-height: 1024px) {
    height: 65vh!important;
  }

  @media (min-width: 1024px) and (min-height: 1280px) {
    height: 35vh!important;
  }

  @media (min-width:  768px) and (max-width: 1024px) {
    height: 80vh!important;  
  }

  @media (min-width:  640px) and (max-width: 768px)
  {
    height: 45vh!important;  
  }

  @media (min-width:  480px) and (max-width: 768px)
  {
    height: 40vh!important;  
  }

  @media (min-width:  320px) and (max-width: 468px)
  {
   height: 33vh!important;  
  }
*/
  .RPW__intro {
    display: flex;
    position: relative;
    z-index: 5;
    flex-direction: column;
    width: calc(100% - 2.4rem);
    padding-right: 2.4rem;
    background-color: var(--${(props) => props.themeBackground});

    @media (min-width: 640px) {
      width: calc(40% - 2.4rem);
    }

    @media (min-width: 1024px) {
      width: calc(35% - 2.4rem);
    }

    &::before {
      @media (min-width: 640px) {
        content: '';
        display: block;
        position: absolute;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: var(--${(props) => props.themeBackground});
      }
    }

    .RPW__intro--copy {
      h3 {
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        line-height: 2rem;
      }

      h2 {
        margin-bottom: 3.2rem;
        font-family: var(--font-serif);
        font-size: 3.2rem;
        line-height: 3.8rem;

        &.RPW__header--alt {
          max-width: 40rem;
          margin-top: 1.6rem;
          padding-left: 2.4rem;
          border-left: 1px solid var(--${(props) => props.themeDivider});
        }

        @media (min-width: 1024px) {
          font-size: 4.2rem;
          line-height: 4.8rem;
        }
      }
    }

    .RPW__intro--controls {
      display: none;

      @media (min-width: 640px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
      }
    }
  }

  .RPW__carousel {
    position: relative;
    z-index: 0;
    width: 100%;
    }
  }
`;


const Slides = styled(GenericCarousel)`
  align-items: center;
  padding-bottom: 4.8rem;
  overflow: visible;

  .swiper-scrollbar {
    background-color: transparent;

    .swiper-scrollbar-drag {
      background-color: var(--${(props) => props.themeText});
    }

    &::after {
      background-color: var(--${(props) => props.themeText});
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    /*width: auto;
     max-width: 32rem; */
     width:92vw;
    height: unset;
    padding: 0 1.5rem;

    &:first-of-type {
      padding-left: 1%;
    }

    &:last-of-type {
      padding-right: 1%;
    }
  }

  .swiper-slide-prev {
    display: flex;
    align-items: center;
    /*width: auto;
     max-width: 32rem; */
     width:92vw;
    height: unset;
    padding: 0 1.5rem;

    @media (min-width: 768px)
    {
      width:90vw; 
      padding-right: 3rem;
    }

    @media (min-width: 640px) and (max-width: 768px)
    {
      width:85vw; 
      padding-right: 3rem;
    }

    @media (min-width: 468px) and (max-width: 640px)
    {
      width:87vw; 
      padding-right: 3rem;
    }

    @media (min-width: 320px) and (max-width: 468px)
    {
      width:81vw; 
      padding-right: 3rem;
    }
  }

  .swiper-slide-active {
    display: flex;
    align-items: center;
    /*width: auto;
     max-width: 32rem; */
     width:92vw;
    height: unset;
    padding: 0 1.5rem;

    @media (min-width: 768px)
    {
      width:90vw; 
      padding-right: 3rem;
      padding-left: 3rem;
    }

    @media (min-width: 640px) and (max-width: 768px)
    {
      width: 90vw;
      padding-right: 3rem;
      padding-left: 3rem;
    }

    @media (min-width: 468px) and (max-width: 640px)
    {
      width:87vw; 
      padding-right: 3rem;
      padding-left: 3rem;
    }

    @media (min-width: 320px) and (max-width: 468px)
    {
      width:81vw; 
      padding-right: 3rem;
      padding-left: 3rem;
    }
`;

Banners.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Subtitle: PropTypes.string,
    Headline: PropTypes.string,
    component: PropTypes.string,
    Pills: PropTypes.array,
  }),
};

Banners.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
    Subtitle: null,
    Headline: null,
    component: null,
    Pills: null,
  },
};
