import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import Richtext from '../../helpers/Richtext';
import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';

//

const TextBlock = ({ content }) => {
  const theme = ColorPicker(content.BgColor, false);

  return (
    <SbEditable content={content}>
      <TextBlockWrap
        themeBackground={theme.background}
        themeText={theme.text}
        typeface={content.Style}
        center={content.Center}
      >
        <TextBlockContainer>
          <h2>{content.Headline}</h2>

          {content.Copy && content.Copy.content[0].content && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: Richtext(content.Copy),
              }}
            />
          )}
        </TextBlockContainer>
      </TextBlockWrap>
    </SbEditable>
  );
};

export default TextBlock;

const TextBlockContainer = styled(Container)`
  padding: 0;
`;

const TextBlockWrap = styled.section`
  padding: 4rem;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});
  font-family: var(--font-${(props) => props.typeface});
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  @media (min-width: 640px) {
    padding: 4.8rem;
  }

  h2 {
    font-size: 3.4rem;
    line-height: 4rem;

    @media (min-width: 640px) {
      font-size: 4.2rem;
      line-height: 4.8rem;
    }
  }

  p {
    margin-bottom: 2.4rem;
    font-size: 1.8rem;
    line-height: 2.4rem;

    a {
      transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) opacity;
      opacity: 1;
      color: var(--${(props) => props.themeText});
      text-decoration: underline;
      text-decoration-color: var(--${(props) => props.themeHighlight});

      &:hover {
        opacity: 0.7;
      }
    }

    &:first-of-type {
      margin-top: 3.2rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (min-width: 640px) {
      font-size: 2.6rem;
      line-height: 3.2rem;
    }
  }
`;

TextBlock.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    Style: PropTypes.string,
    Headline: PropTypes.string,
    Copy: PropTypes.object,
    Center: PropTypes.bool,
  }),
};

TextBlock.defaultProps = {
  content: {
    BgColor: 'grey--0',
    Style: 'serif',
    Headline: 'Lorem ipsum...',
    Copy: null,
    Center: false,
  },
};
