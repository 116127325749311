import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TridentCap from '../../assets/dividers/trident-cap.inline.svg';

//

export const CappedDivider = ({ shape, fill, stroke, fixedHeight }) => (
  <DividerWrap fillColor={fill} strokeColor={stroke} fixedHeight={fixedHeight}>
    <div
      className={`divider__shape--${shape} divider__cap divider__cap--start`}
    >
      {shape === 'trident' && <TridentCap />}
    </div>
    <div className="divider__line" />
    <div className={`divider__shape--${shape} divider__cap divider__cap--end`}>
      {shape === 'trident' && <TridentCap />}
    </div>
  </DividerWrap>
);

const DividerWrap = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: unset;
  }

  .divider__line {
    width: 90%;
    height: 1px;
    background-color: var(--${(props) => props.fillColor || props.strokeColor});

    @media (min-width: 768px) {
      width: 1px;
      height: ${(props) => (props.fixedHeight ? '16rem' : '100%')};
    }
  }

  .divider__cap {
    border-width: 0.1rem;
    border-style: solid;
    ${(props) =>
      `border-color: var(--${props.strokeColor || props.fillColor})`};
    ${(props) =>
      props.fillColor && `background-color: var(--${props.fillColor})`};
  }

  .divider__shape--diamond {
    width: 0.7rem;
    height: 0.7rem;
    transform: rotate(45deg);
  }

  .divider__shape--circle {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 100%;
  }

  .divider__shape--trident {
    position: absolute;
    width: 0.7rem;
    height: 3.2rem;
    border-top: 0;
    border-bottom: 0;
    background-color: transparent;

    &.divider__cap--start {
      right: 1.2rem;
      width: 1.2rem;
      height: 3.3rem;
      transform: rotate(90deg);
      border: 0;

      @media (min-width: 768px) {
        top: -3.2rem;
        right: unset;
        width: 1.1rem;
        height: 3.2rem;
        padding-top: 1.6rem;
        transform: unset;
      }

      svg {
        width: 100%;

        path {
          stroke: currentColor;
        }
      }
    }

    &.divider__cap--end {
      left: 1.2rem;
      width: 1.2rem;
      height: 3.3rem;
      transform: rotate(-90deg);
      border: 0;

      @media (min-width: 768px) {
        bottom: -3.2rem;
        left: unset;
        width: 1.1rem;
        height: 3.2rem;
        padding-top: 1.6rem;
        transform: rotate(180deg);
      }

      svg {
        width: 100%;

        path {
          stroke: currentColor;
        }
      }
    }
  }
`;

CappedDivider.propTypes = {
  shape: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

CappedDivider.defaultProps = {
  shape: 'diamond',
  fill: 'black',
  stroke: 'black',
};
