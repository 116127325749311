import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//

const QuoteFrame = ({ stroke, fill, flip }) => (
  <FrameWrap strokeColor={stroke} fillColor={fill} flip={flip}>
    <svg
      width="272"
      height="60"
      viewBox="0 0 272 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M271 59C237.026 23.291 189.117 1 136.036 1C82.9553 1 35.0463 23.291 1 59"
        strokeMiterlimit="16"
      />
      <rect y="58" width="272" height="2" />
    </svg>
  </FrameWrap>
);

export default QuoteFrame;

const FrameWrap = styled.div`
  display: flex;
  justify-content: center;
  transform: rotate(${(props) => (props.flip ? '180' : '0')}deg);
  border-bottom: 0.2rem solid var(--${(props) => props.strokeColor});

  svg {
    position: relative;
    bottom: -0.4rem;

    path {
      stroke-width: 0.2rem;
      stroke: var(--${(props) => props.strokeColor});
      fill: var(--${(props) => props.fillColor});
    }

    rect {
      fill: var(--${(props) => props.fillColor});
    }
  }
`;

QuoteFrame.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  flip: PropTypes.bool,
};

QuoteFrame.defaultProps = {
  stroke: 'black',
  fill: 'grey--0',
  flip: false,
};
