import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import Richtext from '../../helpers/Richtext';
import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';

//

const ColumnedTextBlockWithHeadline = ({ content }) => {
  const theme = ColorPicker(content.BgColor, false);

  return (
    <SbEditable content={content}>
      <TextWrap
        themeBackground={theme.background}
        themeText={theme.text}
        columns={content.Columns}
        styleContent={content.Style}
      >
        <Container>
        <h2>{content.Headline}</h2>
          {content.Copy && (
            <div
              className="columns"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: Richtext(content.Copy),
              }}
            />
          )}
        </Container>
      </TextWrap>
    </SbEditable>
  );
};

export default ColumnedTextBlockWithHeadline;

const TextWrap = styled.section`
  padding: 5.6rem 4rem;
  column-count: 1;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});
  font-family: var(--font-${(props) => (props.styleContent === 'serif' ? 'serif' : 'sans-serif')});

  @media (min-width: 640px) {
    padding: 6.4rem 4rem;
  }

  @media (min-width: 768px) {
    padding: 8.2rem 4.8rem;
  }

   h2 {
    font-size: 3.4rem;
    line-height: 4rem;
    text-align:center;
    padding-bottom:6rem;

    @media (min-width: 640px) {
      font-size: 4.2rem;
      line-height: 4.8rem;
      text-align:center;
      padding-bottom:6rem;
    }
  }

  .columns {
    column-count: 1;

    @media (min-width: 640px) {
      column-count: 2;
    }

    @media (min-width: 768px) {
      column-count: ${(props) => props.columns};
    }
  }

  p {
    margin-bottom: 2.4rem;
    font-size: 2rem;
    line-height: 2.6rem;

    a {
      transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) opacity;
      opacity: 1;
      color: var(--${(props) => props.themeText});
      text-decoration: underline;
      text-decoration-color: var(--${(props) => props.themeHighlight});

      &:hover {
        opacity: 0.7;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

ColumnedTextBlockWithHeadline.propTypes = {
  content: PropTypes.shape({
    Headline: PropTypes.string,
    Style: PropTypes.string,
    BgColor: PropTypes.string,
    Columns: PropTypes.string,
    Copy: PropTypes.object,
  }),
};

ColumnedTextBlockWithHeadline.defaultProps = {
  content: {
    Headline: null,
    Style:'serif',
    BgColor: 'grey--0',
    Columns: '2',
    Copy: null,
  },
};
