import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';
import Button from '../Button';

//

const ProcessVertical = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);
  const sliderRef = useRef(null);

  function drawLines() {
    const items = sliderRef.current.childNodes;

    items.forEach((el) => {
      const line = el.querySelector('.line');
      if (el.nextSibling) {
        const nextItem = el.nextSibling.offsetHeight;
        const thisItem = el.offsetHeight;

        if (nextItem > thisItem) {
          line.style.height = `calc(${el.nextSibling.offsetHeight}px + 48px + 9px)`;
        } else {
          line.style.height = `calc(${el.nextSibling.offsetHeight}px + 48px + 27px)`;
        }
      }
    });
  }

  useEffect(() => drawLines(), []);
  useEffect(() => window.addEventListener('resize', () => drawLines()));

  function handleHover(e) {
    const allChildrenArr = [...sliderRef.current.children];
    const activeChildren = allChildrenArr.find(
      (el) => el.className === 'active'
    );

    if (activeChildren) {
      activeChildren.classList.remove('active');
    }
    e.currentTarget.parentNode.classList.add('active');
  }

  return (
    <SbEditable content={content}>
      <ProcessVerticalWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
        contrast={
          theme.background.includes('--3') || theme.background === 'white'
        }
      >
        <ProcessVerticalContainer>
          <div className="process__copy">
            {content.Subheading && <h3>{content.Subheading}</h3>}
            {content.Headline && <h2>{content.Headline}</h2>}
            {content.Copy && <p>{content.Copy}</p>}
          </div>

          <div className="process__slider">
            {content.Processes.length > 0 && (
              <ul ref={sliderRef}>
                {content.Processes.map((process, index) => {
                  let isActive = '';

                  if (content.Processes.length > 1) {
                    if (index === 1) {
                      isActive = 'active';
                    }
                  }

                  if (content.Processes.length === 1) {
                    isActive = 'active';
                  }

                  return (
                    <li key={process._uid} className={isActive}>
                      <div
                        className="item"
                        tabIndex={0}
                        onMouseOver={(e) => handleHover(e)}
                        onFocus={(e) => handleHover(e)}
                        onClick={(e) => handleHover(e)}
                        onKeyDown={(e) => handleHover(e)}
                        role="button"
                      >
                        <div className="process__slider--content">
                          <h3>{process.Title}</h3>
                          <div className="divider">
                            <span className="diamond" />
                            <span className="line" />
                          </div>
                          <div className="copy">
                            <p>{process.Copy}</p>
                            {process.Button.length !== 0 && (
                              <Button
                                content={{
                                  label: process.Button[0].label,
                                  to: process.Button[0].to,
                                  arrow: process.Button[0].arrow,
                                }}
                                outline
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </ProcessVerticalContainer>
      </ProcessVerticalWrap>
    </SbEditable>
  );
};

export default ProcessVertical;

const ProcessVerticalContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ProcessVerticalWrap = styled.section`
  padding: 3rem;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  *, ;after, :before {

  }

  @media (min-width: 640px) {
    padding: 6.4rem 4rem;
  }

  @media (min-width: 768px) {
    padding: 7.2rem 4rem;
  }

  .process__copy {
    width: 100%;
    margin-bottom: 4rem;

    @media (min-width: 640px) {
      max-width: 56rem;
      margin: 0 auto 4rem;
    }

    @media (min-width: 768px) {
      width: calc(65% - 6.4rem);
      max-width: unset;
      margin: 0 6.4rem 0 0;
    }

    @media (min-width: 1024px) {
      width: calc(50% - 12.8rem);
      margin-right: 12.8rem;
    }

    h3 {
      margin-bottom: 1.6rem;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    h2 {
      margin-bottom: 4rem;
      padding-bottom: 4rem;
      border-bottom: 1px solid var(--${(props) => props.themeDivider});
      font-family: var(--font-serif);
      font-size: 3.2rem;
      line-height: 3.8rem;

      @media (min-width: 1280px) {
        font-size: 4.2rem;
        line-height: 4.8rem;
      }
    }

    p {
      max-width: 43.2rem;
      font-family: var(--font-serif);
      font-size: 1.8rem;
      line-height: 2.4rem;

      @media (min-width: 1280px) {
        font-size: 2.6rem;
        line-height: 3.2rem;
      }
    }
  }

  .process__slider {
    width: 100%;

    @media (min-width: 768px) {
      width: 45%;
    }

    @media (min-width: 1024px) {
      width: 50%;
    }

    ul {
      display: grid;
      grid-gap: 4.8rem;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        position: relative;
        align-items: center;
        min-height: 4rem;

        --transition: 500ms ease all;

        @media (min-width: 640px) {
          width: 100%;
          margin: unset;
        }

        > button {
          border: 0;
          outline: none;
          background-color: transparent;
        }

        .process__slider--content {
          display: grid;
          position: relative;
          z-index: 10;
          grid-gap: 1.6rem;
          grid-template-columns: 7rem 1.6rem 1fr;
          align-items: center;

          @media (min-width: 768px) {
            grid-gap: 2.4rem;
            grid-template-columns: 8rem 1.6rem 1fr;
          }

          @media (min-width: 1280px) {
            grid-gap: 3.2rem;
            grid-template-columns: 18rem 1.6rem 1fr;
          }
        }

        .divider {
          .diamond {
            display: block;
            position: absolute;
            z-index: 5;
            width: 0.9rem;
            height: 0.9rem;
            transform: rotate(45deg);
            transform-origin: center;
            transition: var(--transition);
            background-color: var(
              --${(props) => (!props.contrast ? props.themeHighlight : props.themeText)}
            );
          }

          .line {
            display: block;
            position: absolute;
            z-index: 0;
            width: 1px;
            height: calc(100% + 3.2rem + 2.4rem);
            margin-left: 0.4rem;
            background-color: var(
              --${(props) => (!props.contrast ? props.themeText : props.themeDivider)}
            );

            @media (min-width: 768px) {
              height: calc(100% + 3.2rem + 0.2rem);
            }

            @media (min-width: 1024px) {
              height: calc(100% + 3.2rem + 0.9rem);
            }
          }
        }

        h3 {
          transition: var(--transition);
          color: var(--${(props) => props.themeText});
          font-size: 1.4rem;
          line-height: 1.8rem;
          text-align: right;

          @media (min-width: 1280px) {
            font-size: 1.8rem;
            line-height: 2rem;
          }
        }

        .copy button {
          display: flex;
          margin-top: 1.6rem;
          transition: var(--transition);
          opacity: 0;

          @media (min-width: 768px) {
            display: none;
          }

          @media (min-width: 1024px) {
            display: flex;
          }
        }

        p {
          transition: var(--transition);
          opacity: 0;
          color: var(
            --${(props) => (props.contrast ? 'white' : props.themeText)}
          );
          font-size: 1.6rem;
          line-height: 1.8rem;
          text-align: left;

          @media (min-width: 1024px) {
            font-size: 1.8rem;
            line-height: 2.4rem;
          }

          @media (min-width: 1280px) {
            font-size: 2.6rem;
            line-height: 3.2rem;
          }
        }

        &:last-of-type {
          .divider .line {
            display: none;
          }
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 5;
          top: -1.6rem;
          left: -3.2rem;
          width: 100%;
          height: 100%;
          padding: 1.6rem 3.2rem;
          transition: var(--transition);
          border-radius: 22rem;
          opacity: 0;
          background-color: ${(props) =>
            props.themeHighlight !== 'white'
              ? `var(--${props.themeHighlight})`
              : `rgba(255, 255, 255, 0.25)`};

          @media (min-width: 1024px) {
            top: -3.2rem;
            left: -3.2rem;
            padding: 3.2rem;
          }
        }

        &.active {
          .diamond {
            margin: 0;
            transform: rotate(45deg) scale(1.5);
            background-color: var(
              --${(props) => (!props.contrast ? props.themeText : props.themeDivider)}
            );
          }

          h3,
          p {
            color: var(
              --${(props) => (props.contrast ? 'white' : props.themeText)}
            );
          }

          h3 {
            font-family: var(--font-serif);
            font-size: 2rem;
            line-height: 2.6rem;

            @media (min-width: 1280px) {
              font-size: 2.6rem;
              line-height: 3.2rem;
            }
          }

          .copy button {
            opacity: 1;
          }

          p {
            opacity: 1;
          }

          &:after {
            opacity: 1;
            box-sizing:initial !important;
          }
        }

      }
    }
  }
`;

ProcessVertical.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Subheading: PropTypes.string,
    Headline: PropTypes.string,
    Copy: PropTypes.string,
    Processes: PropTypes.array,
  }),
};

ProcessVertical.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
    Subheading: null,
    Headline: 'Lorem ipsum...',
    Copy: null,
    Processes: null,
  },
};
