import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import Richtext from '../../helpers/Richtext';
import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';
import ImageMask from '../ImageMask';

//

const TextImageSplitBlock = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  return (
    <SbEditable content={content}>
      <TextImageSplitWrapper
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <TextImageSplitContainer flip={content.Flip}>
          <div className="TISB__text">
            <div className="TISB__text--top">
              <h2>{content.Headline}</h2>
              {content.Subtext && (
                <p className="TISB__subtext">{content.Subtext}</p>
              )}
            </div>

            <div className="TISB__text--bottom">
              {content.CopyIntro && <h3>{content.CopyIntro}</h3>}

              {content.Copy && (
                <div
                  className="ITB__content--copy"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: Richtext(content.Copy),
                  }}
                />
              )}
            </div>
          </div>

          <div className="TISB__image">
            {content.Image && content.Image.length > 0 && (
              <ImageMask
                shape={content.Image[0].ImageShape}
                src={content.Image[0].ImageSource}
                contain={content.Image[0].ImageContain}
              />
            )}
          </div>
        </TextImageSplitContainer>
      </TextImageSplitWrapper>
    </SbEditable>
  );
};

export default TextImageSplitBlock;

const TextImageSplitContainer = styled(Container)`
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr;
  align-items: center;
  direction: ${(props) => (props.flip ? 'rtl' : 'ltr')};

  > * {
    direction: ltr;
  }

  @media (min-width: 1024px) {
    grid-gap: 9.6rem;
    grid-template-columns: 38rem 1fr;
    padding: 0;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 43.5rem 1fr;
  }
`;

const TextImageSplitWrapper = styled.section`
  padding: 3rem;
  overflow-x: hidden;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 768px) {
    padding: 4.8rem;
  }

  .TISB__text {
    max-width: 64rem;

    h2 {
      color: var(--${(props) => props.themeHighlight});
      font-family: var(--font-serif);
      font-size: 3.4rem;
      line-height: 4rem;

      @media (min-width: 640px) {
        font-size: 4.2rem;
        line-height: 4.8rem;
      }
    }

    h3 {
      margin-bottom: 3.2rem;
      font-family: var(--font-serif);
      font-size: 2.6rem;
      line-height: 3.2rem;
    }

    p {
      margin-bottom: 2.4rem;
      font-family: var(--font-sans);
      font-size: 1.4rem;
      line-height: 2rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) opacity;
        opacity: 1;
        color: var(--${(props) => props.themeText});
        text-decoration: underline;
        text-decoration-color: var(--${(props) => props.themeHighlight});

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .TISB__text--bottom {
      margin-top: 3.2rem;
      padding-top: 3.2rem;
      border-top: 1px solid var(--${(props) => props.themeDivider});
    }
  }

  .TISB__image span {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 32rem;
    background-color: var(--${(props) => props.themeDivider});
  }
`;

TextImageSplitBlock.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Headline: PropTypes.string,
    Subtext: PropTypes.string,
    CopyIntro: PropTypes.string,
    Copy: PropTypes.object,
    Image: PropTypes.array,
    Flip: PropTypes.bool,
  }),
};

TextImageSplitBlock.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
    Headline: 'Lorem ipsum...',
    Subtext: null,
    CopyIntro: null,
    Copy: null,
    Image: null,
    Flip: false,
  },
};
