import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';

import BevelledCorners from '../../assets/masks/bevelled-corners.svg';
import Hourglass from '../../assets/masks/hourglass.svg';
import Pinched from '../../assets/masks/pinched.svg';
import Round from '../../assets/masks/round.svg';
import SlightlyRound from '../../assets/masks/slightly-round.svg';
import SquaredCorners from '../../assets/masks/squared-corners.svg';
import BevelledCornersPortrait from '../../assets/masks/bevelled-corners-vert.svg';
import HourglassPortrait from '../../assets/masks/hourglass-vert.svg';
import PinchedPortrait from '../../assets/masks/pinched-vert.svg';
import RoundPortrait from '../../assets/masks/round-vert.svg';
import SlightlyRoundPortrait from '../../assets/masks/slightly-round-vert.svg';
import SquaredCornersPortrait from '../../assets/masks/squared-corners-vert.svg';

//

const ImageMask = ({ shape, src, orientation, contain }) => {
  if (!src) return null;

  let LimitHeight = null;
  if (orientation === 'landscape' && !contain) {
    LimitHeight = 700;
  }

  const fluidProps = getFluidGatsbyImage(src.filename, {
    maxWidth: 1080,
    maxHeight: LimitHeight,
  });

  return (
    <ShapeWrap
      className={`mask__${shape}`}
      orientation={orientation}
      contain={contain}
    >
      <Img fluid={fluidProps} alt={src.alt} />
    </ShapeWrap>
  );
};

export default ImageMask;

const ShapeWrap = styled.div`
  position: relative;
  overflow: hidden;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;

  .gatsby-image-wrapper img {
    object-fit: ${(props) => (props.contain ? 'contain' : 'cover')} !important;
  }

  &.mask__BevelledCorners {
    mask-image: url(${(props) =>
      props.orientation === 'landscape'
        ? BevelledCorners
        : BevelledCornersPortrait});
  }

  &.mask__Hourglass {
    mask-image: url(${(props) =>
      props.orientation === 'landscape' ? Hourglass : HourglassPortrait});
  }

  &.mask__Pinched {
    mask-image: url(${(props) =>
      props.orientation === 'landscape' ? Pinched : PinchedPortrait});
  }

  &.mask__Round {
    mask-image: url(${(props) =>
      props.orientation === 'landscape' ? Round : RoundPortrait});
  }

  &.mask__SlightlyRound {
    mask-image: url(${(props) =>
      props.orientation === 'landscape'
        ? SlightlyRound
        : SlightlyRoundPortrait});
  }

  &.mask__SquaredCorners {
    mask-image: url(${(props) =>
      props.orientation === 'landscape'
        ? SquaredCorners
        : SquaredCornersPortrait});
  }
`;

ImageMask.propTypes = {
  shape: PropTypes.string,
  src: PropTypes.object,
  orientation: PropTypes.string,
  contain: PropTypes.bool,
};

ImageMask.defaultProps = {
  shape: 'square',
  src: null,
  orientation: 'landscape',
  contain: false,
};
