import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import Richtext from '../../helpers/Richtext';
import { CappedDivider } from '../Dividers';
import Button from '../Button';
import Container from '../Container';

//

const FiftyFiftyTextBlock = ({ customTheme, content, transparent }) => {
  const theme = customTheme || ColorPicker(content.BgColor, false);

  return (
    <SbEditable content={content}>
      <FiftyFiftyWrap
        themeBackground={transparent ? 'transparent' : theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <FiftyFiftyContainer>
          {content.Headline && <h2>{content.Headline}</h2>}

          <CappedDivider
            shape={
              content.Divider.length > 0 && content.Divider[0].DividerShape
            }
            stroke={
              content.Divider.length > 0 &&
              content.Divider[0].DividerStyle === 'outlined'
                ? theme.text
                : null
            }
            fill={
              content.Divider.length > 0 &&
              content.Divider[0].DividerStyle === 'filled'
                ? theme.text
                : null
            }
          />

          <div className="FFW__copy">
            {content.Copy && (
              <div
                className="FFW__content"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: Richtext(content.Copy),
                }}
              />
            )}

            {content.Button && content.Button.length !== 0 && (
              <div className="FFW__button">
                <Button
                  customTheme={theme}
                  content={content.Button[0]}
                  outline
                />
              </div>
            )}
          </div>
        </FiftyFiftyContainer>
      </FiftyFiftyWrap>
    </SbEditable>
  );
};

export default FiftyFiftyTextBlock;

const FiftyFiftyContainer = styled(Container)`
  display: grid;
  grid-gap: 3.2rem;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-gap: 3.2rem;
    grid-template-columns: 1fr 4.6rem 1fr;
    width: calc(100% - 9.6rem);
    padding: 0 4.8rem;

    @media (min-width: 768px) {
      width: calc(85% - 9.6rem);
    }
  }

  @media (min-width: 1024px) {
    grid-gap: 5.6rem;
  }
`;

const FiftyFiftyWrap = styled.section`
  padding: 3.2rem 1.6rem;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    padding: 4.8rem;
  }

  h2 {
    font-family: var(--font-serif);
    font-size: 3.4rem;
    line-height: 4rem;
    text-align: center;

    @media (min-width: 768px) {
      align-self: center;
      font-size: 3.2rem;
      line-height: 3.8rem;
      text-align: right;
    }

    @media (min-width: 1024px) {
      font-size: 4.2rem;
      line-height: 4.8rem;
    }
  }

  .FFW__copy {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      align-items: flex-start;
      padding: 1.6rem 0;
    }

    .FFW__content {
      margin-bottom: 2.4rem;
    }

    p {
      margin-bottom: 1.6rem;
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: center;

      &:last-of-type {
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        text-align: left;
      }

      a {
        transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) opacity;
        opacity: 1;
        color: var(--${(props) => props.themeText});
        text-decoration: underline;
        text-decoration-color: var(--${(props) => props.themeHighlight});

        &:hover {
          opacity: 0.7;
        }
      }

      .FFW__button {
        align-self: center;
        max-width: 20rem;

        @media (min-width: 768px) {
          align-self: flex-start;
        }
      }
    }
  }
`;

FiftyFiftyTextBlock.propTypes = {
  customTheme: PropTypes.object,
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    Headline: PropTypes.string,
    Divider: PropTypes.array,
    Copy: PropTypes.object,
    Button: PropTypes.array,
  }),
  transparent: PropTypes.bool,
};

FiftyFiftyTextBlock.defaultProps = {
  customTheme: null,
  content: {
    BgColor: 'grey--0',
    Headline: null,
    Divider: null,
    Copy: null,
    Button: null,
  },
  transparent: false,
};
