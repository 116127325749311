import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import Container from '../Container';
import ImageMask from '../ImageMask';
import ColorPicker from '../../helpers/ColorPicker';

//

const ImageColumns = ({ content }) => {
  if (!content.Images) return null;

  const theme = ColorPicker(content.BgColor, false);

  return (
    <SbEditable content={content}>
      <ImagesWrap
        
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
      >
        <ImagesContainer cols={content.Images.length}>
          {content.Images.map((image) => (
            <div
              className={`IC__image${
                image.ImageCaption ? ` IC__image--has-caption` : ''
              }`}
              key={image._uid}
            >
              <ImageMask
                src={image.ImageSource}
                shape={image.ImageShape}
                orientation={
                  content.Images.length > 2 ? 'portrait' : 'landscape'
                }
                contain={image.ImageContain}
              />
              {image.ImageCaption && <p>{image.ImageCaption}</p>}
            </div>
          ))}
        </ImagesContainer>
      </ImagesWrap>
    </SbEditable>
  );
};

export default ImageColumns;

const ImagesContainer = styled(Container)`
  display: grid;
  grid-auto-flow: row dense;
  grid-gap: 2.4rem;
  grid-template-columns: repeat(auto-fill, minmax(26rem, 1fr));
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0;

  @media (min-width: 768px) {
    grid-template-columns: repeat(${(props) => props.cols}, 1fr);
  }
`;

const ImagesWrap = styled.section`
  padding: 4.8rem;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  .IC__image {
    flex: 1 1 32rem;

    & > div,
    .gatsby-image-wrapper {
      height: 100%;
    }

    &.IC__image--has-caption {
      & > div {
        height: calc(90% - 4rem);
      }
      p {
        height: 10%;
        margin-top: 4rem;
        font-size: 1.4rem;
        line-height: 2rem;
        text-align: center;
      }
    }
  }
`;

ImageColumns.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    Images: PropTypes.array,
    ImageContain: PropTypes.bool,
  }),
};

ImageColumns.defaultProps = {
  content: {
    BgColor: 'white',
    Images: null,
    ImageContain: false,
  },
};
