import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';
import Button from '../Button';
import { ShapeOne, ShapeTwo } from './Shapes';
import { CappedDivider } from '../Dividers';
import Icon from '../Icon';

//

const LargeSpaciousTextBlock = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  function ShapePicker(shape, fill, stroke) {
    switch (shape) {
      case 'ShapeTypeOne':
        return <ShapeOne theme={{ fillColor: fill, strokeColor: stroke }} />;
      case 'ShapeTypeTwo':
        return <ShapeTwo theme={{ fillColor: fill, strokeColor: stroke }} />;
      default:
        return null;
    }
  }

  return (
    <SbEditable content={content}>
      <ContentWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <ContentContainer>
          {content.Icon && (
            <div className="LSTB__icon">
              <Icon shape={content.Icon} />
            </div>
          )}

          <h2>{content.Headline}</h2>

          {content.Copy && (
            <>
              {content.Divider.length !== 0 && (
                <div className="LSTB__divider">
                  <CappedDivider
                    shape={content.Divider[0].DividerShape}
                    stroke={
                      content.Divider[0].DividerStyle === 'outlined'
                        ? theme.text
                        : null
                    }
                    fill={
                      content.Divider[0].DividerStyle === 'filled'
                        ? theme.text
                        : null
                    }
                    fixedHeight
                  />
                </div>
              )}

              <p>{content.Copy}</p>
            </>
          )}

          {content.Button.length > 0 && (
            <div className="LSTB__button">
              <Button content={content.Button[0]} />
            </div>
          )}
        </ContentContainer>

        <Shapes>
          {ShapePicker(content.ShapeA, theme.divider, theme.highlight)}
          {ShapePicker(content.ShapeB, theme.divider, theme.highlight)}
        </Shapes>
      </ContentWrap>
    </SbEditable>
  );
};

export default LargeSpaciousTextBlock;

const ContentContainer = styled(Container)`
  display: flex;
  position: relative;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  text-align: center;
`;

const ContentWrap = styled.section`
  display: grid;
  position: relative;
  min-height: calc(90vh - 14.4rem);
  padding: 12.4rem 1.6rem;
  overflow: hidden;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});
  place-content: center;

  @media (min-width: 640px) {
    padding: 7.2rem 5.6rem;
  }

  .LSTB__icon {
    font-size: 3.2rem;

    svg {
      fill: var(--${(props) => props.themeHighlight});
    }
  }

  h2 {
    max-width: 89rem;
    margin: 3.2rem 0;
    font-family: var(--font-serif);
    font-size: 3.4rem;
    line-height: 4rem;
    text-align: center;

    @media (min-width: 640px) {
      margin: 4.8rem 0 5.6rem;
      font-size: 5.6rem;
      line-height: 6.4rem;
    }

    @media (min-width: 1024px) {
      font-size: 7.4rem;
      line-height: 8rem;
    }
  }

  .LSTB__divider {
    display: none;

    @media (min-width: 640px) {
      display: unset;
      margin-bottom: 5.6rem;
    }
  }

  p {
    max-width: 50rem;
    margin-bottom: 3.6rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

const Shapes = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .shapewrap {
    position: absolute;

    &:nth-of-type(1) {
      top: 0;
      left: 0;
      transform: translate3d(-50%, -50%, 0);
    }

    &:nth-of-type(2) {
      right: 0;
      bottom: 0;
      transform: translate3d(50%, 60vh, 0);

      @media (min-width: 640px) {
        transform: translate3d(50%, 50%, 0);
      }
    }
  }
`;

LargeSpaciousTextBlock.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Icon: PropTypes.string,
    Headline: PropTypes.string,
    Copy: PropTypes.string,
    Button: PropTypes.array,
    ShapeA: PropTypes.string,
    ShapeB: PropTypes.string,
  }),
};

LargeSpaciousTextBlock.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
    Icon: null,
    Headline: '...',
    Copy: null,
    Button: null,
    ShapeA: null,
    ShapeB: null,
  },
};
