import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Vimeo from '@u-wave/react-vimeo';
import getVideoId from 'get-video-id';
import SbEditable from 'storyblok-react';
import { Cookies } from "react-cookie-consent";

import Container from '../Container';
import ColorPicker from '../../helpers/ColorPicker';

const Vimeo_Size = ({ content }) => {
  if (!content.VimeoURL) return null;

  const [isVimeoAllowed, setIsVimeoAllowed] = useState(false);

  useEffect(() => {
    // Check if the user has accepted cookies for Vimeo (or all tracking cookies)
    const vimeoConsent = Cookies.get("gatsby-gdpr-vimeo"); // Replace with the actual cookie name if needed

    // Only allow Vimeo if consent is given
    if (vimeoConsent === "true") {
      setIsVimeoAllowed(true);
    }
  }, []);

  const { id } = getVideoId(content.VimeoURL);
  const theme = ColorPicker(content.BgColor, false);

  let width = content.width;
  let height = content.height;

  return (
    <SbEditable content={content}>
      {isVimeoAllowed ? (
        <VideoWrap themeBackground={theme.background}>
          <Vimeoframe video={id} responsive autoplay width={width} height={height} />
        </VideoWrap>
      ) : (
        // If consent is not given or user hasn't interacted, show a placeholder
        <div style={{ textAlign: "center", padding: "20px", backgroundColor: "#f0f0f0" }}>
          <p>You need to accept cookies to view this Vimeo video.</p>
          
        </div>
      )}
    </SbEditable>
  );
};

export default Vimeo_Size;

// Styled components
const Vimeoframe = styled(Vimeo)`
  position: relative;
  margin: auto;
  width: calc(0.5 * 100vw);

  @media only screen and (max-width: 768px), only screen and (max-device-width: 768px) {
    width: 100%;
  }
  @media only screen and (max-width: 240px), only screen and (max-device-width: 240px) {
    width: 100%;
  }
`;

const VideoWrap = styled.section`
  padding: 4rem;
  margin: auto;
  background-color: var(--${(props) => props.themeBackground});

  @media (min-width: 640px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 768px), only screen and (max-device-width: 768px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 240px), only screen and (max-device-width: 240px) {
    padding: 2rem;
  }
`;

Vimeo_Size.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    VimeoURL: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  }),
};

Vimeo_Size.defaultProps = {
  content: {
    BgColor: 'grey--0',
    VimeoURL: null,
    width: null,
    height: null,
  },
};
