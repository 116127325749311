import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import { Link } from 'gatsby';

import Icon from '../Icon';

//

const SquareSlide = ({ headline, copy, icon, image, theme, link }) => {
  let fluidProps;
  if (image.filename) {
    fluidProps = getFluidGatsbyImage(image.filename, {
      maxWidth: 1080,
    });
  }

  // console.log(link);

  const Slide = () => (
    <SlideWrap
      data-sal="fade"
      data-duration="750"
      themeBackground={theme.background}
      themeDivider={theme.divider}
      themeText={theme.text}
      themeHighlight={theme.highlight}
    >
      <div className="SS__thumb">
        {image.filename && <Img fluid={fluidProps} alt={image.alt} />}
      </div>

      <div className="SS__content">
        {icon && (
          <div className="SS__content--icon">
            <Icon shape={icon} />
          </div>
        )}
        {headline && <div className="SS__content--headline">{headline}</div>}
        {copy && <div className="SS__content--copy">{copy}</div>}
      </div>
    </SlideWrap>
  );

  if (link && link.linktype === 'url') {
    return (
      <a href={link.url} target="_blank" rel="noreferrer noopener">
        <Slide />
      </a>
    );
  }

  if (link && link.linktype === 'story') {
    return (
      <Link to={`/${link.cached_url}`}>
        <Slide />
      </Link>
    );
  }

  return <Slide />;
};

export default SquareSlide;

const SlideWrap = styled.div`
  position: relative;

  .SS__thumb {
    width: 60%;

    .gatsby-image-wrapper {
      height: 32rem;

      @media (min-width: 768px) {
        height: 40rem;
      }

      @media (min-width: 1024px) {
        height: 48rem;
      }
    }
  }

  .SS__content {
    position: absolute;
    top: 50%;
    right: 0;
    width: 50%;
    padding: 2.4rem;
    transform: translateY(-50%);
    background-color: var(--${(props) => props.themeText});
    color: var(--${(props) => props.themeBackground});

    .SS__content--icon svg {
      fill: var(--${(props) => props.themeBackground});
    }

    .SS__content--headline {
      margin: 1.2rem 0;
      font-family: var(--font-serif);
      font-size: clamp(2.4rem, 2.2vw, 4.2rem);
      line-height: 1.2;
    }

    .SS__content--copy {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
`;

SquareSlide.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  theme: PropTypes.object,
};

SquareSlide.propTypes = {
  headline: null,
  copy: null,
  icon: null,
  image: null,
  theme: null,
};
