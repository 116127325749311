import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import Container from '../Container';
import ColorPicker from '../../helpers/ColorPicker';
import GenericCarousel from './GenericCarousel';
import CarouselControls from './CarouselControls';
import RolloverPill from './RolloverPillFixTotal';

//

const RolloverPillsFixTotal = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [totalSlides, setTotalSlides] = useState(null);
  const [activeSlide, setActiveSlide] = useState(null);
  return (
    <SbEditable content={content}>
      <RolloverPillsWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <RolloverPillsContainer>
          <div className="RPW__intro">
            <div className="RPW__intro--copy">
              {content.Subtitle && <h3>{content.Subtitle}</h3>}
              {content.Headline && (
                <h2
                  className={`${
                    content.component === 'RolloverPillsDataFixTotal'
                      ? 'RPW__header--alt'
                      : 'RPW__header'
                  }`}
                >
                  {content.Headline}
                </h2>
              )}
            </div>

            <div className="RPW__intro--controls">
              <CarouselControls
                activeSlide={activeSlide}
                totalSlides={totalSlides}
                slidesPerView={3}
                prevRef={prevRef}
                nextRef={nextRef}
                themeText={theme.text}
                themeHighlight={theme.highlight}
              />
            </div>
          </div>

          <div className="RPW__carousel">
            <Slides
              controls={{ prevRef, nextRef }}
              totalSlides={setTotalSlides}
              activeSlide={setActiveSlide}
              themeText={theme.text}
              themeDivider={theme.divider}
            >
              {content.Pills?.map((pill) => (
                <RolloverPill key={pill._uid} content={pill} theme={theme} />
              ))}
            </Slides>
          </div>

        </RolloverPillsContainer>
      </RolloverPillsWrap>
    </SbEditable>
  );
};

export default RolloverPillsFixTotal;

const RolloverPillsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  overflow: visible;

  @media (min-width: 640px) {
    flex-direction: row;
  }
`;

const RolloverPillsWrap = styled.section`
  padding: 4rem;
  overflow: hidden;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    padding: 4.8rem;
  }

  .RPW__intro {
    display: flex;
    position: relative;
    z-index: 5;
    flex-direction: column;
    width: calc(100% - 2.4rem);
    padding-right: 2.4rem;
    background-color: var(--${(props) => props.themeBackground});

    @media (min-width: 640px) {
      width: calc(40% - 2.4rem);
    }

    @media (min-width: 1024px) {
      width: calc(35% - 2.4rem);
    }

    @media (min-width: 1200px) {
      width: calc(40% - 2.4rem);
    }

    &::before {
      @media (min-width: 640px) {
        content: '';
        display: block;
        position: absolute;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: var(--${(props) => props.themeBackground});
      }
    }

    .RPW__intro--copy {
      h3 {
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        line-height: 2rem;
      }

      h2 {
        margin-bottom: 3.2rem;
        font-family: var(--font-serif);
        font-size: 3.2rem;
        line-height: 3.8rem;

        &.RPW__header--alt {
          max-width: 40rem;
          margin-top: 1.6rem;
          padding-left: 2.4rem;
          border-left: 1px solid var(--${(props) => props.themeDivider});
        }

        @media (min-width: 1024px) {
          font-size: 4.2rem;
          line-height: 4.8rem;
        }
      }
    }

    .RPW__intro--controls {
      display: none;

      @media (min-width: 640px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
      }
    }
  }

  .RPW__carousel {
    position: relative;
    z-index: 0;
    width: 100%;

    .swiper-slide:last-child {
      margin-right:45px;
      margin-left: 10px;

      @media only screen and (min-width: 1024px), only screen and (min-device-width: 1024px) {
        margin-right:200px;
      }

      @media only screen and (min-width: 1200px), only screen and (min-device-width: 1200px) {
        margin-right:200px;
      }

      @media only screen and (min-width: 1400px), only screen and (min-device-width: 1400px) {
        margin-right:400px;
      }
    }

    .swiper-slide-active:last-child {
      margin-left: 10px;
    }

    .swiper-slide-prev:nth-last-child(2) {
      margin-left: 250px;
    }

    
    @media only screen and (min-width: 1400px), only screen and (min-device-width: 1400px) {
      .swiper-slide-active:last-child {
        margin-left: 50px;
      }

      .swiper-slide-prev:nth-last-child(2) {
        margin-left: 250px;
      }

      .swiper-slide-next:nth-child(2) {
        margin-left: 10px;
      }

      .swiper-slide-prev.swiper-slide-prev ~ .swiper-slide-prev {
        margin-left: 250px;
      }
    }

    @media (min-width: 640px) {
      width: 60%;
    }

    @media (min-width: 1024px) {
      width: 65%;
    }
  }

  .RPW__carousel::after {
    box-sizing: initial !important;
    content: none;
  }
`;

const Slides = styled(GenericCarousel)`
  align-items: stretch;
  padding-bottom: 4.8rem;
  overflow: visible;

  .swiper-scrollbar {
    background-color: transparent;

    .swiper-scrollbar-drag {
      background-color: var(--${(props) => props.themeText});
    }

    &::after {
      background-color: var(--${(props) => props.themeText});
    }
  }

   .swiper-slide {
    display: flex;
    align-items: stretch;
    width: auto;
    /* max-width: 32rem; */
    height: unset;
    padding: 0 1.6rem;

    &:first-of-type {
      padding-left: 1%;
    }

    &:last-of-type {
      padding-right: 1%;
    }
  }


`;


RolloverPillsFixTotal.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Subtitle: PropTypes.string,
    Headline: PropTypes.string,
    component: PropTypes.string,
    Pills: PropTypes.array,
  }),
};

RolloverPillsFixTotal.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
    Subtitle: null,
    Headline: null,
    component: null,
    Pills: null,
  },
};
