import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import Container from '../Container';
import ColorPicker from '../../helpers/ColorPicker';
import Button from '../Button';

//

const TextWithCTA = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  return (
    <SbEditable content={content}>
      <TextWithCTAWrap
        themeBackground={theme.background}
        themeText={theme.text}
      >
        <TextWithCTAContainer cols={!!content.Text}>
          {content.Text && (
            <div className="copy">
              <p>{content.Text}</p>
            </div>
          )}

          <div className="cta">
            {content.CTA && content.CTA.length !== 0 && (
              <div className="FFW__button">
                <Button customTheme={theme} content={content.CTA[0]} outline />
              </div>
            )}
          </div>
        </TextWithCTAContainer>
      </TextWithCTAWrap>
    </SbEditable>
  );
};

export default TextWithCTA;

const TextWithCTAContainer = styled(Container)`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  align-items: center;

  @media (min-width: 768px) {
    grid-template-columns: ${({ cols }) => (cols ? '2fr 1fr' : '1fr')};
  }
`;

const TextWithCTAWrap = styled.section`
  padding: 3rem;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    padding: 6.4rem 4.2rem;
  }

  .copy {
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: center;

      @media (min-width: 768px) {
        text-align: right;
      }
    }
  }

  .cta {
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
      justify-content: ${({ cols }) => (cols ? 'flex-start' : 'center')};
    }
  }
`;

TextWithCTA.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Text: PropTypes.string,
    CTA: PropTypes.array,
  }),
};

TextWithCTA.defaultProps = {
  content: {
    BgColor: 'white',
    AltColors: false,
    Text: null,
    CTA: null,
  },
};
