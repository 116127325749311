import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import SbEditable from 'storyblok-react';

//

const FullBleedImage = ({ content }) => {
  if (!content.ImageSource) return null;

  const fluidProps = getFluidGatsbyImage(content.ImageSource.filename, {
    maxWidth: 1920,
    // maxHeight: 1080,
  });

  return (
    <SbEditable content={content}>
      <section data-sal="fade" data-duration="750">
        <Img fluid={fluidProps} alt={content.ImageSource.alt} />
      </section>
    </SbEditable>
  );
};

export default FullBleedImage;

FullBleedImage.propTypes = {
  content: PropTypes.shape({
    ImageSource: PropTypes.object,
  }),
};

FullBleedImage.defaultProps = {
  content: {
    ImageSource: null,
  },
};
