import React from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

//

SwiperCore.use([Navigation, Scrollbar]);

//

// *
// ** To use a custom controller, pass in an array of refs containing the buttons
// ** ie. [PrevButtonRef, NextButtonRef] -- in a PREV / NEXT order.
// *

//

const GenericCarousel = ({
  children,
  slidesPerView,
  spaceBetween,
  controls,
  className,
  totalSlides,
  activeSlide,
}) => {
  if (!children) return null;

  function handleSwiperInit(swiper) {
    if (controls) {
      swiper.params.navigation.prevEl = controls.prevRef.current;
      swiper.params.navigation.nextEl = controls.nextRef.current;
    }

    swiper.init(true);
    console.log('swiper:', swiper);
    console.log('spaceBetween:', spaceBetween);

    if (activeSlide && totalSlides) {
      activeSlide(swiper.snapIndex + 1);
      totalSlides(swiper.snapGrid.length );
    }
  }

  function handleSlideChange(swiper) {
    if (activeSlide && totalSlides) {
      activeSlide(swiper.snapIndex + 1);
    }
  }
  
  return (
    <Swiper
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      navigation={{
        prevEl: null,
        nextEl: null,
      }}
      init={false}
      onSwiper={(swiper) => handleSwiperInit(swiper)}
      onSlideChange={(swiper) => handleSlideChange(swiper)}
      scrollbar={{ draggable: true }}
      className={className}
    >
      {children.length &&
        children.map((child) => (
          <SwiperSlide key={child.key}>{child}</SwiperSlide>
        ))}
    </Swiper>
  );
};

export default GenericCarousel;

GenericCarousel.propTypes = {
  children: PropTypes.node,
  slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  spaceBetween: PropTypes.number,
  controls: PropTypes.object,
  className: PropTypes.string,
  totalSlides: PropTypes.func,
  activeSlide: PropTypes.func,
};

GenericCarousel.defaultProps = {
  children: null,
  slidesPerView: 'auto',
  spaceBetween: 0,
  controls: null,
  className: null,
};
