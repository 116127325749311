import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import ImageMask from '../ImageMask';

//

const InternalLink = ({ link, children }) => {
  if (!link || (link && link.linktype !== 'story')) return null;
  return (
    <Link to={`/${link.cached_url}`} style={{ color: 'unset' }}>
      {children}
    </Link>
  );
};

InternalLink.propTypes = {
  link: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

//

const ExternalLink = ({ link, children }) => {
  if (!link || (link && link.linktype !== 'url')) return null;
  return (
    <a href={link.cached_url} style={{ color: 'unset' }}>
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  link: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

//

const CopyWithImageSlide = (props) => {
  const { link } = props;

  if (link && link.id && link.linktype === 'story') {
    return (
      <InternalLink link={link}>
        <SlideContents {...props} />
      </InternalLink>
    );
  }

  if (link && link.id && link.linktype === 'url') {
    return (
      <ExternalLink link={link}>
        <SlideContents {...props} />
      </ExternalLink>
    );
  }

  return <SlideContents {...props} />;
};

export default CopyWithImageSlide;

CopyWithImageSlide.propTypes = {
  link: PropTypes.object,
};

CopyWithImageSlide.defaultProps = {
  link: null,
};

//

const SlideContents = ({ subtitle, copy, image, className }) => (
  <CopyImageWrap className={className}>
    <div className="CWIS__content">
      {subtitle && <h5>{subtitle}</h5>}
      {copy && <p>{copy}</p>}
    </div>

    <div className="CWIS__image">
      {image.length > 0 && (
        <ImageMask
          shape={image[0].ImageShape}
          src={image[0].ImageSource}
          orientation="landscape"
        />
      )}
    </div>
  </CopyImageWrap>
);

const CopyImageWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .CWIS__content {
    max-width: 43.6rem;

    h5 {
      margin-bottom: 1.6rem;
      font-family: var(--font-sans);
      font-size: 1.4rem;
      line-height: 2rem;

      @media (min-width: 768px) {
        margin-bottom: 0.8rem;
      }
    }

    p {
      font-family: var(--font-serif);
      font-size: 1.8rem;
      line-height: 2.4rem;
      white-space: break-spaces;
    }
  }

  .CWIS__image {
    margin-top: 2.4rem;

    @media (min-width: 640px) {
      margin-top: 3.2rem;
    }
  }
`;

SlideContents.propTypes = {
  subtitle: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

SlideContents.defaultProps = {
  subtitle: null,
  copy: null,
  image: null,
  className: null,
};
