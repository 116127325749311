import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import RolloverPills from '../RolloverPills';
import ColorPicker from '../../helpers/ColorPicker';
import ImageMask from '../ImageMask';
import Button from '../Button';
import Container from '../Container';

//

const RolloverPillsCTA = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  console.log(content);
  console.log(theme);

  return (
    <SbEditable content={content}>
      <CTAWrapper
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.background.replace('0', '2')}
      >
        <CTAContainer>
          <div className="RPCTA__cta">
            <div className="RPCTA__cta--image">
              <ImageMask
                shape={
                  content.Image.length !== 0 && content.Image[0].ImageShape
                }
                src={content.Image.length !== 0 && content.Image[0].ImageSource}
              />
            </div>

            <div className="RPCTA__cta--copy">
              {content.Subtitle && <h3>{content.Subtitle}</h3>}
              {content.Headline && <h2>{content.Headline}</h2>}
              {content.Button.length > 0 && (
                <Button content={content.Button[0]} customTheme={theme} />
              )}
            </div>
          </div>

          <div className="RPCTA__slider">
            {content.Pills.length !== 0 && (
              <RolloverPills
                theme={{
                  themeBackground: theme.background,
                  themeText: theme.text,
                  themeHighlight: theme.highlight,
                }}
                content={content.Pills[0]}
              />
            )}
          </div>
        </CTAContainer>
      </CTAWrapper>
    </SbEditable>
  );
};

export default RolloverPillsCTA;

const CTAContainer = styled(Container)`
  padding: 0;
`;

const CTAWrapper = styled.section`
  overflow-x: hidden;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  .RPCTA__cta {
    display: flex;
    flex-direction: column;
    padding: 4rem;

    @media (min-width: 640px) {
      padding-bottom: 0;
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: 1024px) {
      padding: 4.6rem;
    }

    .RPCTA__cta--image {
      width: 100%;
      margin-bottom: 2.4rem;

      @media (min-width: 768px) {
        width: 50%;
        margin-bottom: unset;
      }
    }

    .RPCTA__cta--copy {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;

      @media (min-width: 768px) {
        align-items: unset;
        width: calc(50% - 4rem);
        margin-left: 4rem;
        text-align: unset;
      }

      @media (min-width: 1024px) {
        width: calc(50% - 8rem);
        margin-left: 8rem;
      }

      @media (min-width: 1280px) {
        width: calc(50% - 13.4rem);
        margin-left: 13.4rem;
      }

      h3 {
        font-size: 1.4rem;
        line-height: 2rem;
      }

      h2 {
        margin: 1.6rem 0 1.8rem;
        font-family: var(--font-serif);
        font-size: 3.4rem;
        line-height: 4rem;

        @media (min-width: 768px) {
          margin: 1.6rem 4.2rem 4.2rem 0;
          padding-left: 4rem;
          border-left: 1px solid var(--${(props) => props.themeText});
          font-size: 3.8rem;
          line-height: 4.4rem;
        }

        @media (min-width: 1280px) {
          font-size: 4.2rem;
          line-height: 4.8rem;
        }
      }
    }
  }

  .RPCTA__slider {
    position: relative;
    width: calc(100% + 6.4rem);
    padding: 3.2rem 0;

    .swiper-scrollbar {
      overflow: hidden;
    }

    @media (min-width: 640px) {
      padding: 3.2rem;
    }

    @media (min-width: 768px) {
      width: calc(100% - 1.2rem);
    }

    @media (min-width: 1024px) {
      padding: 4.6rem;
      padding-right: 0;
    }

    > section {
      /* Targeting nested pills wrapper */
      padding: 4rem;
      overflow: hidden;
      background-color: var(--${(props) => props.themeDivider});
      color: var(--${(props) => props.textColor});

      @media (min-width: 640px) {
        padding: 4.8rem;
      }

      .RPW__intro {
        background-color: var(--${(props) => props.themeDivider});

        &::before {
          background-color: var(--${(props) => props.themeDivider});
        }
      }

      .RP__pill {
        background-color: var(--${(props) => props.themeHighlight});
        color: var(
          --${(props) => (props.themeHighlight.includes('grey') || props.themeBackground.includes('earth--0') ? props.themeBackground : props.themeText)}
        );
      }

      .RP__pill--icon svg {
        fill: var(--${(props) => props.themeDivider});
      }

      svg {
        width: 2rem;
        height: 2rem;
      }

      .RP__arrow,
      .RP__desc,
      .Controls--left,
      .Controls--right {
        color: var(--${(props) => props.themeText});
      }

      > div {
        /* Targeting the nested pills container */
        /* margin: 0; */

        @media (min-width: 640px) {
          margin: 0;
        }
      }
    }
  }
`;
