import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';
import QuoteFrame from '../Frames/QuoteFrame';
import QuoteMark from './QuoteMark';
import CarouselControls from '../CarouselControls';

//

SwiperCore.use([Navigation]);

//

const LargeQuoteTextBlock = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [totalSlides, setTotalSlides] = useState(null);
  const [activeSlide, setActiveSlide] = useState(null);

  function handleSwiperInit(swiper) {
    swiper.params.navigation.prevEl = prevRef.current;
    swiper.params.navigation.nextEl = nextRef.current;

    swiper.init(true);

    setActiveSlide(swiper.activeIndex + 1);
    setTotalSlides(swiper.slides.length);
  }

  function handleSlideChange(swiper) {
    setActiveSlide(swiper.activeIndex + 1);
  }

  return (
    <SbEditable content={content}>
      <QuoteWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <QuoteContainer>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            autoHeight
            threshold={8}
            navigation={{
              prevEl: null,
              nextEl: null,
            }}
            init={false}
            onSwiper={(swiper) => handleSwiperInit(swiper)}
            onSlideChange={(swiper) => handleSlideChange(swiper)}
          >
            {content.Quotes.map((quote) => (
              <SwiperSlide key={quote._uid}>
                <blockquote>
                  <QuoteFrame stroke={theme.divider} fill={theme.background} />
                  <div className="LQTB__content">
                    <QuoteMark fill={theme.highlight} />
                    <p>{quote.Blockquote}</p>
                    <QuoteMark fill={theme.highlight} flip />
                  </div>
                  <QuoteFrame
                    stroke={theme.divider}
                    fill={theme.background}
                    flip
                  />

                  {quote.CitationName && (
                    <footer>
                      <cite>
                        {quote.CitationName}
                        <span>{quote.CitationRole}</span>
                      </cite>
                    </footer>
                  )}
                </blockquote>
              </SwiperSlide>
            ))}

            <div className="LQTB__controls">
              <CarouselControls
                activeSlide={activeSlide}
                totalSlides={totalSlides}
                prevRef={prevRef}
                nextRef={nextRef}
                themeText={theme.text}
                themeHighlight={theme.highlight}
              />
            </div>
          </Swiper>
        </QuoteContainer>
      </QuoteWrap>
    </SbEditable>
  );
};

export default LargeQuoteTextBlock;

const QuoteContainer = styled(Container)`
  width: 100%;
  padding: 0;
  overflow: hidden;

  @media (min-width: 640px) {
    max-width: 100%;
  }
`;

const QuoteWrap = styled.section`
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 768px) {
    border: 48px solid
      ${(props) =>
        props.themeDivider === 'black'
          ? '#bbb'
          : `var(--${props.themeDivider})`};
  }

  blockquote {
    position: relative;
    z-index: 10;
    margin: 0;
    padding: 5.6rem 0;

    @media (min-width: 768px) {
      padding: 5.6rem;
    }
  }

  .LQTB__content {
    @media (min-width: 768px) {
      border-right: 0.2rem solid var(--${(props) => props.themeDivider});
      border-left: 0.2rem solid var(--${(props) => props.themeDivider});
    }

    p {
      padding: 4.4rem;
      font-family: var(--font-serif);
      font-size: 3.4rem;
      line-height: 4rem;
      text-align: center;

      @media (min-width: 640px) {
        padding: 8.8rem 4.4rem;
      }

      @media (min-width: 1024px) {
        font-size: 4.2rem;
        line-height: 4.8rem;
      }
    }
  }

  .LQTB__controls {
    display: flex;
    position: absolute;
    z-index: 20;
    bottom: 7.2rem;
    left: 5.6rem;
    justify-content: center;
    width: calc(100% - 11.2rem);
    font-size: 1.4rem;
    line-height: 2.4rem;

    @media (min-width: 768px) {
      justify-content: flex-start;
      width: unset;
    }

    button {
      display: flex;
      align-items: center;
      padding: 0;
      border: 0;
      outline: none;
      background: transparent;
      color: var(--${(props) => props.themeHighlight});
      cursor: pointer;
    }

    svg {
      width: 2rem;
      height: 2rem;
    }

    .LQTB__controls--left {
      margin-right: 1.6rem;
    }

    .LQTB__controls--right {
      margin-left: 1.6rem;
    }

    .LQTB__controls--disabled {
      opacity: 0.4;
      cursor: not-allowed !important;
    }
  }

  footer {
    display: flex;
    position: relative;
    bottom: 5.6rem;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 10rem 0 2rem;

    @media (min-width: 768px) {
      position: absolute;
      flex-direction: row;
      width: calc(100% - 11.2rem);
      padding: unset;
    }

    cite {
      display: flex;
      flex-direction: column;
      font-family: var(--font-serif);
      font-size: 1.8rem;
      font-style: normal;
      line-height: 2.4rem;
      text-align: center;

      @media (min-width: 768px) {
        text-align: right;
      }

      span {
        margin-top: 0.2rem;
        color: var(--${(props) => props.themeHighlight});
        font-family: var(--font-sans);
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }
`;

LargeQuoteTextBlock.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Quotes: PropTypes.array,
  }),
};

LargeQuoteTextBlock.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
    Blockquote: '...',
    CitationName: null,
    CitationRole: null,
    Quotes: null,
  },
};
