import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';

import BevelledCorners from '../../assets/masks/bevelled-corners-wide.svg';
import Hourglass from '../../assets/masks/hourglass-wide.svg';
import Pinched from '../../assets/masks/pinched-wide.svg';
import Round from '../../assets/masks/round-wide.svg';
import SlightlyRound from '../../assets/masks/slightly-round-wide.svg';
import SquaredCorners from '../../assets/masks/squared-corners-wide.svg';

//

const FullWidthImage = ({ content, customTheme, keepCaption }) => {
  if (!content.ImageSource) return null;

  const theme = ColorPicker(customTheme || content.BgColor, false);

  const fluidProps = getFluidGatsbyImage(content.ImageSource.filename, {
    maxWidth: 1440,
    maxHeight: 900,
  });

  const Quote = () => (
    <div className="FWIW__quote">
      <blockquote>
        <p>&ldquo;{content.ImageQuote}&rdquo;</p>

        {content.ImageQuoteCitation && (
          <footer>
            <cite>&mdash; {content.ImageQuoteCitation}</cite>
          </footer>
        )}
      </blockquote>
    </div>
  );

  return (
    <SbEditable content={content}>
      <FullWidthImageWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        keepCaption={keepCaption}
      >
        <FullWidthImageContainer>
          <div className={`FWIW__container mask__${content.ImageShape}`}>
            {content.ImageQuote && <Quote />}

            <Img fluid={fluidProps} alt={content.ImageSource.alt} />
          </div>

          {keepCaption && <Quote />}
        </FullWidthImageContainer>
      </FullWidthImageWrap>
    </SbEditable>
  );
};

export default FullWidthImage;

const FullWidthImageContainer = styled(Container)`
  width: 100%;
  padding: 0;
`;

const FullWidthImageWrap = styled.section`
  padding: 3rem;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    padding: 4.8rem;
  }

  .FWIW__quote {
    display: flex;
    justify-content: center;

    @media (min-width: 640px) {
      display: none;
    }

    blockquote {
      max-width: 32rem;
      margin: 3.2rem 0 0 0;
      padding: 0 2.4rem;
      padding: 0 1.6rem;
      font-size: 1.4rem;
      text-align: center;

      footer cite {
        display: block;
        margin-top: 1.6rem;
        font-style: normal;
      }
    }
  }

  .FWIW__container {
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    position: relative;

    &.mask__BevelledCorners {
      mask-image: url(${BevelledCorners});
    }

    &.mask__Hourglass {
      mask-image: url(${Hourglass});
    }

    &.mask__Pinched {
      mask-image: url(${Pinched});
    }

    &.mask__Round {
      mask-image: url(${Round});
    }

    &.mask__SlightlyRound {
      mask-image: url(${SlightlyRound});
    }

    &.mask__SquaredCorners {
      mask-image: url(${SquaredCorners});
    }

    .FWIW__quote {
      display: none;

      @media (min-width: 640px) {
        display: flex;
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 75%;
        background-image: linear-gradient(
          to bottom,
          transparent 10%,
          rgba(40, 40, 40, 1)
        );
        background-blend-mode: multiply;
      }

      blockquote {
        display: unset;
        width: 75%;
        max-width: 50rem;
        margin: 0;
        margin-bottom: 4.8rem;
        color: var(--white);
        font-size: 1.2rem;
        line-height: 1.8rem;
        text-align: center;

        @media (min-width: 768px) {
          font-size: 1.4rem;
          line-height: 2rem;
        }

        footer cite {
          display: block;
          margin-top: 2.4rem;
          font-style: normal;
        }
      }
    }

    .gatsby-image-wrapper {
      z-index: 5;
    }
  }
`;

FullWidthImage.propTypes = {
  customTheme: PropTypes.string,
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    ImageSource: PropTypes.object,
    ImageQuote: PropTypes.string,
    ImageQuoteCitation: PropTypes.string,
  }),
  keepCaption: PropTypes.bool,
};

FullWidthImage.defaultProps = {
  customTheme: null,
  content: {
    BgColor: 'grey--0',
    ImageSource: null,
    ImageQuote: null,
    ImageQuoteCitation: null,
  },
  keepCaption: true,
};
