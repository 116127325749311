import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import GenericCarousel from '../GenericCarousel';
import CopyWithImageSlide from './CopyWithImageSlide';
import Container from '../Container';

//
// * Slider — Copy with Image
//

const CopyWithImageSlider = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  return (
    <SbEditable content={content}>
      <CopyWithImageWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <SliderContainer>
          {(content.Subtitle || content.Headline) && (
            <div className="CWIW__headline">
              {content.Subtitle && <h3>{content.Subtitle}</h3>}
              {content.Headline && <h2>{content.Headline}</h2>}
            </div>
          )}

          {content.Slides && (
            <Slides themeText={theme.text} themeDivider={theme.divider}>
              {content.Slides.map((slide) => (
                <CopyWithImageSlide
                  key={slide._uid}
                  subtitle={slide.Subtitle}
                  copy={slide.Copy}
                  image={slide.Image}
                  link={slide.Link}
                />
              ))}
            </Slides>
          )}
        </SliderContainer>
      </CopyWithImageWrap>
    </SbEditable>
  );
};

export default CopyWithImageSlider;

const SliderContainer = styled(Container)`
  overflow: visible;
`;

const CopyWithImageWrap = styled.section`
  padding: 4.8rem 0;
  overflow: hidden;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  .CWIW__headline {
    margin-bottom: 4.8rem;
    padding-left: 1%;

    h3 {
      max-width: 70rem;
      margin-bottom: 0.8rem;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    h2 {
      max-width: 70rem;
      color: var(--${(props) => props.themeHighlight});
      font-family: var(--font-serif);
      font-size: 3.4rem;
      line-height: 4rem;

      @media (min-width: 768px) {
        font-size: 4.2rem;
        line-height: 4.8rem;
      }
    }
  }
`;

const Slides = styled(GenericCarousel)`
  align-items: stretch;
  padding-bottom: 4.8rem;
  overflow: visible;

  .swiper-scrollbar {
    background-color: transparent;

    .swiper-scrollbar-drag {
      background-color: var(--${(props) => props.themeText});
    }

    &::after {
      background-color: var(--${(props) => props.themeText});
    }
  }

  .swiper-slide {
    width: 40%;
    height: auto;
    padding: 0 2.4rem;
    border-right: 1px solid var(--${(props) => props.themeDivider});

    @media (max-width: 768px) {
      width: 85%;
    }

    &:first-of-type {
      padding-left: 1%;
    }

    &:last-of-type {
      padding-right: 1%;
      border-right: 0;
    }
  }
`;

CopyWithImageSlider.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Slides: PropTypes.arrayOf(PropTypes.object),
    Subtitle: PropTypes.string,
    Headline: PropTypes.string,
  }),
};

CopyWithImageSlider.defaultProps = {
  content: {
    BgColor: 'grey--0',
    AltColors: false,
    Slides: null,
    Subtitle: null,
    Headline: null,
  },
};
