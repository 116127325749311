import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

const GreenHouseEmbed = ({ content }) => {
  const [reloadKey, setReloadKey] = useState(0); // Key to force iframe reload

  const originalUrl = 'https://boards.greenhouse.io/embed/job_board/js?for=fulcrumglobaltechnologies';
  const careersUrl = `${originalUrl}&key=${reloadKey}`; // Use reloadKey to change URL

  useEffect(() => {
    const handleUserInteraction = () => {
      // When user interacts with the job board, increment reloadKey
      setReloadKey(prevKey => prevKey + 1);
    };

    // Add a click listener to detect interaction on the job board
    const jobBoardElement = document.getElementById('grnhse_app');
    if (jobBoardElement) {
      jobBoardElement.addEventListener('click', handleUserInteraction);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      if (jobBoardElement) {
        jobBoardElement.removeEventListener('click', handleUserInteraction);
      }
    };
  }, []);

  return (
    <SbEditable content={content}>
      <GreenHouse>
        <div id="grnhse_app">
          <script type="text/javascript" defer src={careersUrl}  style={{ display: 'none' }}></script> 

          <iframe src={careersUrl} width="100%" height="500px" frameBorder="0" title="Greenhouse Job Board" style={{ display: 'block' }}></iframe> 

        </div>
      </GreenHouse>
    </SbEditable>
  );
};

export default GreenHouseEmbed;

const GreenHouse = styled.section`
  padding: 0;
`;

GreenHouseEmbed.propTypes = {
  content: PropTypes.shape({
    Embed: PropTypes.string,
  }),
};

GreenHouseEmbed.defaultProps = {
  content: {
    Embed: null,
  },
};
