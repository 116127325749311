import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BsArrowDownShort } from 'react-icons/bs';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import { Link } from 'gatsby';

import Icon from '../Icon';
import Richtext from '../../helpers/Richtext';

//

const RolloverPill = ({ content, theme }) => {
  if (!content.Link) return null;

  let fluidProps = null;
  if (content.Image && content.Image.id) {
    fluidProps = getFluidGatsbyImage(content.Image.filename, {
      maxWidth: 1080,
    });
  }

  const PillContent = () => (
    <>
      <div
        className={`RP__pill ${
          content.Image && content.Image.id !== null ? 'RP__pill--hasHover' : ''
        }`}
      >
        {content.Image && content.Image.id && (
          <div className="RP__pill--image">
            <Img fluid={fluidProps} alt={content.Image.alt} />
          </div>
        )}

        {content.Icon && (
          <div className="RP__pill--icon">
            <Icon shape={content.Icon} />
          </div>
        )}

        <h3>{content.Title}</h3>

        {content.Copy ? (
          <div
            className="RP__copy"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: Richtext(content.Copy) }}
          />
        ) : (
          content.Subtitle && <h4>{content.Subtitle}</h4>
        )}
      </div>

      {content.Description && (
        <>
          <div className="RP__arrow">
            <BsArrowDownShort />
          </div>

          <p className="RP__desc">{content.Description}</p>
        </>
      )}
    </>
  );

  return (
    <RolloverPillWrap
      themeBackground={theme.background}
      themeDivider={theme.divider}
      themeText={theme.text}
      themeHighlight={theme.highlight}
      squared={content.Squared}
      className={`${content.Description ? 'hasDesc' : ''}`}
    >
      {content.Link.cached_url !== '' && content.Link.linktype === 'story' && (
        <Link to={`/${content.Link.cached_url}`}>
          <PillContent />
        </Link>
      )}
      {content.Link.url !== '' && content.Link.linktype === 'url' && (
        <a href={content.Link.url} target="_blank" rel="noreferrer noopener">
          <PillContent />
        </a>
      )}

      {!content.Link.url && !content.Link.cached_url && <PillContent />}
    </RolloverPillWrap>
  );
};

export default RolloverPill;

const RolloverPillWrap = styled.div`
  /* display: flex; */
  /* align-items: stretch; */
  /* max-width: 32rem; */
  width: 32rem;

  a {
    display: flex;
    align-items: stretch;
  }

  &.hasDesc,
  &.hasDesc a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a {
    color: var(--${(props) => props.themeText});
    text-decoration: none;
  }

  .RP__pill {

    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: calc(100% - 6.4rem);
    /* max-width: 32rem; */
    min-height: 18rem;
    padding: 3.2rem;
    overflow: hidden;
    border-radius: ${(props) => (props.squared ? '0.5rem' : '20rem')};
    background-color: var(
      --${(props) => (props.themeText === props.themeHighlight ? props.themeDivider : props.themeHighlight)}
    );
    color: var(
      --${(props) => (props.themeText === props.themeHighlight ? props.themeText : 'white')}
    );
    text-align: center;

    @media (min-width: 1280px) {
      width: calc(100% - 9.6rem);
      padding: 4.8rem;
    }

    &.RP__pill--hasHover {
      .RP__pill--icon,
      h3,
      h4 {
        z-index: 10;
        transition: 250ms cubic-bezier(0.47, 0, 0.745, 0.715) all;
        opacity: 1;
      }

      &:hover {
        transition: 250ms cubic-bezier(0.47, 0, 0.745, 0.715) all;
        background-color: transparent;

        .RP__pill--icon,
        h3,
        h4 {
          opacity: 0;
        }

        .RP__pill--image {
          transform: scale(1);
          opacity: 1;
        }
      }
    }

    .RP__pill--image {
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(1.1);
      transition: 250ms cubic-bezier(0.47, 0, 0.745, 0.715) all;
      opacity: 0;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    .RP__pill--icon {
      align-self: center;
      margin-bottom: 1.6rem;

      svg {
        fill: var(--${(props) => props.themeText});
      }
    }

    h3 {
      padding: 0 3.2rem;
      font-family: var(--font-serif);
      font-size: 3.4rem;
      font-weight: 700;
      line-height: 3.4rem;

      @media (min-width: 640px) {
        font-size: 3.8rem;
        line-height: 4rem;
      }
    }

    h4 {
      margin-top: 0.8rem;
      font-size: 1.8rem;
      line-height: 2.4rem;
      text-align: center;

      @media (min-width: 640px) {
        font-size: 2.5rem;
        line-height: 2.8rem;
      }
    }
  }

  .RP__arrow {
    margin: 3.2rem 0 2.4rem;
    color: var(--${(props) => props.themeHighlight});

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .RP__copy {
    margin-top: 0.8rem;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: center;

    a {
      transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) opacity;
      opacity: 1;
      color: var(--${(props) => props.themeText});
      text-decoration: underline;
      text-decoration-color: var(--${(props) => props.themeHighlight});

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .RP__desc {
    font-family: var(--font-serif);
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
  }
`;

RolloverPill.propTypes = {
  content: PropTypes.shape({
    Icon: PropTypes.string,
    Title: PropTypes.string,
    Subtitle: PropTypes.string,
    Description: PropTypes.string,
    Image: PropTypes.object,
    Link: PropTypes.object,
  }),

  theme: PropTypes.shape({
    background: PropTypes.string,
    divider: PropTypes.string,
    text: PropTypes.string,
    highlight: PropTypes.string,
  }),
};

RolloverPill.defaultProps = {
  content: {
    Icon: null,
    Title: 'Lorem ipsum...',
    Subtitle: null,
    Description: null,
    Image: null,
    Link: null,
  },

  theme: {
    background: null,
    divider: null,
    text: null,
    highlight: null,
  },
};
