import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BsArrowDownShort } from 'react-icons/bs';
import Img from 'gatsby-image';
//import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import { Link } from 'gatsby';

import Icon from '../Icon';
import Shape from '../Shape';
import Button from '../Button';
import Richtext from '../../helpers/Richtext';

const Banner = ({ content, theme }) => {
  const name = content.Media.filename;
  const ext  = name.slice(-4);

  // Ensure headline is extracted from content
  const headline = content.Headline;

  const pc = (() => {
    if (ext === '.mp4') {
      return (
        <VideoWrapper>
          <video preload="auto" width="100%" height="100%" loop autoPlay muted>
            <source src={content.Media.filename} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          {/* Headline */}
          {headline && <h1 className="slide-headline">{headline}</h1>}
        </VideoWrapper>
      );
    } else if (ext === '.jpg' || ext === '.jpeg' || ext === '.png') {
      return (
        <ImageWrapper>
          <img
            className="image"
            alt={content.Media.alt}
            src={content.Media.filename}
          />
          {/* Headline */}
          {headline && <h1 className="slide-headline">{headline}</h1>}
        </ImageWrapper>
      );
    }
  })();

  const PillContent = () => <>{pc}</>;

  return <PillContent />;
};

export default Banner;

// Styled-components
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  .image {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
  }


 .slide-headline {
    position: absolute;
    top: 10px; /* Position headline 10px from the top */
    left: 50%; /* Start headline in the middle horizontally */
    transform: translateX(-50%); /* Center it by shifting half its width */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 3;
    margin-top:40px;
    text-align: center;
  }

`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;

  video {
    width: 100%;
    height: auto;
  }


 .slide-headline {
    position: absolute;
    top: 10px; /* Position headline 10px from the top */
    left: 50%; /* Start headline in the middle horizontally */
    transform: translateX(-50%); /* Center it by shifting half its width */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 3;
    margin-top:40px;
    text-align: center;
  }
`;

const BannerWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  direction: ${(props) => (props.flip ? 'rtl' : 'ltr')};
  width: 100%;

  > * {
    direction: ltr;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }

  a {
    display: flex;
    align-items: center;
    color: var(--${(props) => props.themeText});
    text-decoration: none;
  }

  &.hasDesc,
  &.hasDesc a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    /*padding: 4.2rem;*/
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }

    h3 {
      margin-bottom: 1.4rem;
      color: var(--${(props) => props.themeHighlight});
      font-size: 1.4rem;
      line-height: 2rem;
    }

    h2 {
      font-family: var(--font-serif);
      font-size: 3.2rem;
      line-height: 3.8rem;

      @media (min-width: 1024px) {
        font-size: 4.2rem;
        line-height: 4.8rem;
      }
    }

    p {
      margin-top: 2.4rem;
      font-size: 1.4rem;
      line-height: 1.8rem;

      @media (min-width: 1024px) {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
    }

    button {
      margin-top: 6rem;
    }
  }

  .image {
    width: 100%;
    margin-top: 2.4rem;

    @media (min-width: 768px) {
      width: calc(50% - 3.2rem);
      margin-top: unset;
    }

    .gatsby-image-wrapper {
      position: static!important
    }
  }

  image{
    width:  100%;
    height: auto !important;
  }

  .video-wrapper{
    width: 100%;
  }

  video{
    width: 100%;
    height auto!important;
    max-width:540px;
  }
`;

Banner.propTypes = {
  content: PropTypes.shape({
    Headline: PropTypes.string,
    Image: PropTypes.object,
    Video: PropTypes.object,
  }),

  theme: PropTypes.shape({
    background: PropTypes.string,
    divider: PropTypes.string,
    text: PropTypes.string,
    highlight: PropTypes.string,
  }),
};

Banner.defaultProps = {
  content: {
    Headline: null,
    Image: null,
    Video: null,
  },

  theme: {
    background: null,
    divider: null,
    text: null,
    highlight: null,
  },
};
