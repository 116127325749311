import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import SbEditable from 'storyblok-react';

import Container from '../Container';
import ColorPicker from '../../helpers/ColorPicker';
import Richtext from '../../helpers/Richtext';
//

const RapidxEcosystem = ({ content }) => {
const theme = ColorPicker(content.BgColor, false);
  let fluidProps = null;
  if (content.Image && content.Image.id) {
    fluidProps = getFluidGatsbyImage(content.Image.filename, {
      maxWidth: 1080,
    });
  }

//console.log(content.Headline);
//console.log(content.Style);
//console.log(content.Center);

  return (
    <SbEditable content={content}>
      <RapidxEcosystemWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
        centerContent={content.Center}
        styleContent={content.Style}
      >
        <RapidxEcosystemContainer>
          <h2>{content.Headline}</h2>

          {content.Copy && content.Copy.content[0].content && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: Richtext(content.Copy),
              }}
            />
          )}

          <p class="small"><b>{content.text}</b></p>

          {content.Image.id && (
          <div className="image">
            <div className="image--wrap">
              <Img fluid={fluidProps} alt={content.Image.alt} />
            </div>
          </div>
          )}
        </RapidxEcosystemContainer>
      </RapidxEcosystemWrap>
    </SbEditable>
  );
};

export default RapidxEcosystem;

const RapidxEcosystemContainer = styled(Container)`
  padding: 0;
`;

const RapidxEcosystemWrap = styled.section`
  padding: 4rem;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});
  font-family: var(--font-${(props) => (props.styleContent === 'serif' ? 'serif' : 'sans-serif')});
  text-align: ${(props) => (props.centerContent ? 'center' : 'left')};

  @media (min-width: 640px) {
    padding: 4.8rem;
  }

  h2 {
    font-size: 3.4rem;
    line-height: 4rem;

    @media (min-width: 640px) {
      font-size: 4.2rem;
      line-height: 4.8rem;
    }
  }

  p {
    margin-bottom: 2.4rem;
    font-size: 1.8rem;
    line-height: 2.4rem;

    a {
      transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) opacity;
      opacity: 1;
      color: var(--${(props) => props.themeText});
      text-decoration: underline;
      text-decoration-color: var(--${(props) => props.themeHighlight});

      &:hover {
        opacity: 0.7;
      }
    }

    &:first-of-type {
      margin-top: 3.2rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (min-width: 640px) {
      font-size: 2.6rem;
      line-height: 3.2rem;
    }
  }

  .small {
    font-size : 27px;

    @media (min-width: 1024px) {
      font-size: 27px;
    }

    @media (min-width: 991px) {
      font-size: 23px;
    }

    @media (max-width: 768px) {
      font-size: 19px;
    }

    @media (max-width: 640px) {
      font-size: 15px;
    }
  }

  .image {
    width: 100%;
    margin-top: 2.4rem;

    @media (min-width: 768px) {
      width: calc(50% - 3.2rem);
      margin-top: unset;
    }

    .gatsby-image-wrapper {
      position: relative;
      z-index: 15;
    }

    .image--wrap {
      position: relative;
    }
  }
`;

RapidxEcosystem.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    Center: PropTypes.bool,
    Style: PropTypes.string,
    Headline: PropTypes.string,
    Copy: PropTypes.object,
    Image: PropTypes.object,
  }),
};

RapidxEcosystem.defaultProps = {
  content: {
    BgColor: 'grey--0',
    Center:false,
    Style:'serif',
    Headline: null,
    Copy: null,
    Image: null,
  },
};
